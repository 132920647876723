import React from "react";
import { useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom"
// import Form from "react-bootstrap/Form";
// import Table from "react-bootstrap/Table";
// import Modal from "react-bootstrap/Modal";
//img
import logo from "./../../img/logo.png";
// component
import SidebarPage from "./../../component/sidebar";

export default function CgvEditingPage() {
  const [open, setOpen] = React.useState(false);

  const appHeader = () => {
    setOpen(true);
  };

  const [value, setValue] = React.useState("");

  const [editingValue, setEditingValue] = React.useState(value);

  const onChange = (event) => setEditingValue(event.target.value);

  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  const onBlur = (event) => {
    if (event.target.value.trim() === "") {
      setEditingValue(value);
    } else {
      setValue(event.target.value);
    }
  };

  return (
    <>
      <div className="mobile_header" >
        <div className={open ? "mobile_righticon" : "iconText" }>
          <i class="fa fa-list" onClick={appHeader} >
            {open ? <SidebarPage open={open} /> : <img src={logo} className="mobile_logo" alt="" />}
          </i>
        </div>
      </div>
      <div className="rightSection">
          <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "39px" }}>
            <p className="cguTitle" style={{ marginTop: "10px" }}>CGV</p>
            <Link to="/reports"  className="linkUser">
            <Button className="cguEditBtn1">Save</Button>{" "}
            </Link>
          </div>
          <div className="cgvEditingText" style={{ marginTop: "24px" }}>
          <textarea style={{width: "100%"}}
            rows={15}
            aria-label="Field name"
            value={editingValue}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
            {/* <p className="cguText">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. Praesent auctor purus
              luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
              rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
              vel bibendum lorem. Morbi convallis convallis diam sit amet
              lacinia. Aliquam in elementum tellus. Curabitur tempor quis eros
              tempus lacinia. Nam bibendum pellentesque quam a convallis. Sed ut
              vulputate nisi. Integer in felis sed leo vestibulum venenatis.
              Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum
              vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu
              mauris a, blandit ultrices nibh. Mauris sit amet magna non ligula
              vestibulum eleifend. Nulla varius volutpat turpis sed lacinia. Nam
              eget mi in purus lobortis eleifend. Sed nec ante dictum sem
              condimentum ullamcorper quis venenatis nisi. Proin vitae facilisis
              nisi, ac posuere leo. Nam pulvinar blandit velit, id condimentum
              diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi nec,
              fermentum congue felis. Quisque mauris dolor, fringilla sed
              tincidunt ac, finibus non odio. Sed vitae mauris nec ante pretium
              finibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
              dignissim, metus nec fringilla accumsan, risus sem sollicitudin
              lacus, ut interdum tellus elit sed risus. Maecenas eget
              condimentum velit, sit amet feugiat lectus. Class aptent taciti
              sociosqu ad litora torquent per conubia nostra, per inceptos
              himenaeos. Praesent auctor purus luctus enim egestas, ac
              scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac
              rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi
              convallis convallis diam sit amet lacinia. Aliquam in elementum
              tellus.
            </p> */}
          </div>
          <div className="cguDisplay" style={{ marginTop: "31px" }}>
            <p className="cguTitle">Documents</p>
            <p className="addDocument">
              <i class="fa-solid fa-plus"></i> Add a documents
            </p>
          </div>
          <div className="" style={{ marginTop: "24px" }}>
            <p className="cguTitle1">documentexample1.docx</p>
            <p className="addDocument">
              Delete <i class="fa-solid fa-trash-can"></i> 
            </p>
          </div>
          <div className="cguDisplay">
            <p className="cguTitle1">documentexample2.docx</p>
            <p className="addDocument">
              Delete <i class="fa-solid fa-trash-can"></i> 
            </p>
          </div>
        </div>
    </>
  );
}
