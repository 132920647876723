import React from "react";
import { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
//img
import logo from "./../../img/logo.png";
// component
import SidebarPage from "./../../component/sidebar";

export default function CGUPage(props) {
  const { cgu, getCgu, editCgu, updateCgu, cguList, isSaving } = props;

  const [selected, setSelected] = useState({
    name: "",
  });

  useEffect(() => {
    getCgu({});
  }, []);

  const [open, setOpen] = useState(false);

  const appHeader = () => {
    setOpen(true);
  };

  const [showActive, setShowActive] = useState(false);

  const handleClose = () => setShowActive(false);
  const handleShow = () => setShowActive(true);

  const [objective, setObjective] = useState(false);
  
  const [data, editData] = useState(false);

  const handleShowObj = () => {
    setObjective(true);
    editData(true);
  }

  useEffect(() => {
    if (cguList) {
      setSelected({ ...cguList });
    }
  }, [cguList]);

  useEffect(() => {
    if(isSaving){
      getCgu({});
    }
  }, [isSaving]);

  const submitActivity = (event) => {
    event.preventDefault();
    editData("");
    setObjective("");
    if (selected) {
      {
        selected.id
        (updateCgu(selected));
      }
    }
  }

  return (
    <>
      <div className="mobile_header" >
        <div className={open ? "mobile_righticon" : "iconText" }>
          <i class="fa fa-list" onClick={appHeader} >
            {open ? <SidebarPage open={open} /> : <img src={logo} className="mobile_logo" alt="" />}
          </i>
        </div>
      </div>
      <div className="rightSection">
        <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "39px" }}>
          <p className="cguTitle" style={{ marginTop: "10px" }}>
            CGU
          </p>
          {objective ? 
            <Button className="cguEditBtn1" onClick={submitActivity}>
              Save
            </Button>
            :
            <>
              {cgu && Array.isArray(cgu) && cgu
                .map((item) => (
                  <Button className="cguEditBtn" 
                    onClick={() => {
                      editCgu(item.id);
                      handleShowObj();
                    }} >
                    Edit
                  </Button>
              ))}
            </>
          }
        </div>
        { data ? 
          <div className="cgvEditingText" style={{ marginTop: "24px" }}>
            <textarea style={{width: "100%"}}
              rows={15}
              aria-label="Field name"
              value={selected.name}
              onChange={(event) =>
                setSelected({
                  ...selected,
                  name: event.target.value,
                })
              }
            />
          </div>
        :
          <div style={{ marginTop: "24px" }}>
            {cgu && Array.isArray(cgu) && cgu
              .map((item) => (
                <p className="cguText" id="editData">
                  {item.name}
                </p>
            ))}
          </div> 
        }
        <div className="cguDisplay" style={{ marginTop: "31px" }}>
          <p className="cguTitle">Documents</p>
          <p className="addDocument" onClick={handleShow}>
            <i class="fa-solid fa-plus"></i> Add a documents
          </p>
        </div>
        <div className="cguDisplay" style={{ marginTop: "24px" }}>
          <p className="cguTitle1">documentexample1.docx</p>
          <p className="addDocument">
            Delete <i class="fa-solid fa-trash-can"></i>
          </p>
        </div>
        <div className="cguDisplay">
          <p className="cguTitle1">documentexample2.docx</p>
          <p className="addDocument">
            Delete <i class="fa-solid fa-trash-can"></i>
          </p>
        </div>
      </div>
      <div className="">
        <Modal
          className="activeDivModalBox"
          show={showActive}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body closeButton>
            <p className="modalBoxTitle">Add documents</p>
            <input className="form-control" type="file" />
            {/* <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Type"
                className="me-2 modalBoxSearchBar"
                aria-label="Search"
              />
            </Form> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button className="modalConfirm">Confirm</Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
