import { AppRootContainer } from "./../shared/app-root/app-root.container";
import store from "./store";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  return (
    <Provider store={store}>
      <CssBaseline />
      <HashRouter>
        <AppRootContainer />
      </HashRouter>
    </Provider>
  );
}

export default App;
