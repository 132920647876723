import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import dashboard from "./../../src/img/dashboard.png";
import list from "./../../src/img/list.png";
import configuration from "./../../src/img/setting.png";
import support from "./../../src/img/support.png";
import legal from "./../../src/img/legal.png";
import logout from "./../../src/img/logout.png";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
// img
import logo from "./../img/logo.png";
// css
// import "./../index.css";
import { NavLink, useLocation } from "react-router-dom";

export default function SidebarPage(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [openS, setOpenS] = useState(false);
  const [openL, setOpenL] = useState(false);

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("totally custom!")
    );

    return <p onClick={decoratedOnClick}>{children}</p>;
  }

  const handleLogout = () => {
    localStorage.removeItem("admin_access_token");
    window.location.reload();
  };

  return (
    <div className={props.open ? "sidebar sidebarActive" : "sidebar"}>
      <div className="logoTitleWrapper">
        <img src={logo} className="sidenavLogo" alt="" />
        <p className="sidenavTitle">WOFICH</p>
      </div>
      <div className="navListWrapper">
        <ul className="navUl">
          <Link to="/dashboard" className="linkUser">
            <li className="navLi" style={{
              color: location.pathname === '/dashboard' ? '#2f2fc1' : 'black',
            }}>
              <img src={dashboard} alt="dashboard" className="sidebarIcon" />
              Dashboard
            </li>
          </Link>
          <Link to="/list-of-users" className="linkUser">
            <li className="navLi listTop" style={{
              color: location.pathname === '/list-of-users' ? '#2f2fc1' : 'black',
            }}>
              <img src={list} alt="list" className="sidebarIcon" /> List of
              users
            </li>
          </Link>
          <Link to="/configration" className="linkUser">
            <li className="navLi listTop" style={{
              color: location.pathname === '/configration' ? '#2f2fc1' : 'black',
            }}>
              <img
                src={configuration}
                alt="configuration"
                className="sidebarIcon"
              />
              Configuration
            </li>
          </Link>
          <li className="navLi listTop" onClick={() => setOpenS(!openS)} >
            <img src={support} alt="support" className="sidebarIcon" />
            Support
            {openS ? (
              <i className="fa-solid fa-angle-up downIcon"></i>
            ) : (
              <i className="fa-solid fa-angle-down downIcon"></i>
            )}
          </li>
          <Accordion.Collapse in={openS} timeout="auto" unmountOnExit>
            <ul className="navUl">
              <Link to="/support" className="linkUser">
                <li className="navLi listTop" style={{
              color: location.pathname === '/support' ? '#2f2fc1' : 'black',
            }}> Support</li>
              </Link>
              <Link to="/reports" className="linkUser">
                <li className="navLi listTop" style={{
              color: location.pathname === '/reports' ? '#2f2fc1' : 'black',
            }}> Reports</li>
              </Link>
              <Link to="/coach" className="linkUser">
                <li className="navLi listTop" style={{
              color: location.pathname === '/coach' ? '#2f2fc1' : 'black',
            }}>Coach</li>
              </Link>
              <Link to="/status-request" className="linkUser">
                <li className="navLi listTop" style={{
              color: location.pathname === '/status-request' ? '#2f2fc1' : 'black',
            }}>Status Request</li>
              </Link>
            </ul>
          </Accordion.Collapse>
          <li className="navLi listTop" onClick={() => setOpenL(!openL)}>
            <img src={legal} alt="legal" className="sidebarIcon" />
            Legal
            {openL ? (
              <i className="fa-solid fa-angle-up downIcon"></i>
            ) : (
              <i className="fa-solid fa-angle-down downIcon"></i>
            )}
          </li>
          <Accordion.Collapse in={openL}>
            <ul className="navUl">
              <Link to="/legal-cgu" className="linkUser">
                <li className="navLi listTop"  style={{
              color: location.pathname === '/legal-cgu' ? '#2f2fc1' : 'black',
            }}> Legal CGU</li>
              </Link>
              <Link to="/legal-cgv" className="linkUser">
                <li className="navLi listTop"  style={{
              color: location.pathname === '/legal-cgv' ? '#2f2fc1' : 'black',
            }}> Legal CGV</li>
              </Link>
            </ul>
          </Accordion.Collapse>
          <div className="linkUser">
            <Button  onClick={() => handleLogout()} variant="link" className="navBtn listTop">
              <img
                src={logout}
                alt="logout"
                className="logoIcon"   
              />{" "}Logout
            </Button>
            {/* <li className="navLi listTop">
              <img
                src={logout}
                alt="logout"
                className="sidebarIcon"
                onClick={handleLogout}
              />{" "}
              Logout
            </li> */}
          </div>
        </ul>
      </div>
    </div>
  );
}

