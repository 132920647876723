import * as actionTypes from "./subscriptionActionType";


const initialState = {
    isLoading: false,
    isError: false,
    isSaving: false,
    isLoggedIn: localStorage.getItem("admin_access_token") !== null ? true : false,
    loggedUser: localStorage.getItem("admin_details") !== null ? JSON.parse(localStorage.getItem("admin_details")) : {},
    activity: [],
    activityList: {},
    count: 0,
    subscription_data:[],
    subscriptionPaid_data:[],
    isDeleteSubscription:false,
    payment_method_data:[]

}


export const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ALL_SUBSCRIPTION:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false,
            };
        case actionTypes.ALL_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false,
            };
        case actionTypes.ALL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: false,
                subscription_data: action.payload ? action.payload.data : [],

            };
            case actionTypes.ALL_PAID_SUBSCRIPTION:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                    isSaving: false,
                };
            case actionTypes.ALL_PAID_SUBSCRIPTION_FAILURE:
                return {
                    ...state,
                    isError: true,
                    isLoading: false,
                    isSaving: false,
                };
            case actionTypes.ALL_PAID_SUBSCRIPTION_SUCCESS:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    isSaving: false,
                    subscriptionPaid_data: action.payload ? action.payload.data : [],
    
                };
                case actionTypes.CANCEL_SUBSCRIPTION:
                    return {
                        ...state,
                        isLoading: true,
                        isError: false,
                        isDelete: false
                    };
                case actionTypes.CANCEL_SUBSCRIPTION_FAILURE:
                    return {
                        ...state,
                        isLoading: false,
                        isError: true,
                        isDelete: false
                    };
                case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
                    return {
                        ...state,
                        isLoading: false,
                        isError: false,
                        isDeleteSubscription: true
                    };

                    case actionTypes.PYMENT_METHOD_DETILAS:
                        return {
                            ...state,
                            isLoading: true,
                            isError: false,
                            isDelete: false
                        };
                    case actionTypes.PYMENT_METHOD_FAILURE:
                        return {
                            ...state,
                            isLoading: false,
                            isError: true,
                            isDelete: false
                        };
                    case actionTypes.PYMENT_METHOD_SUCCESS:
                        return {
                            ...state,
                            isLoading: false,
                            isError: false,
                            payment_method_data: action.payload ? action.payload.data.rows
                            : [],
                        };
        default:
            return state
    }
}

