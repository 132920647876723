import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "jan",    pv: 800,
    amt: 1400,
  },
  {
    name: "fev",    pv: 967,
    amt: 1506,
  },
  {
    name: "mars",
    pv: 1098,
    amt: 989,
  },
  {
    name: "avr",
    pv: 1200,
    amt: 1228,
  },
  {
    name: "mai",
    pv: 1108,
    amt: 1100,
  },
  {
    name: "juin",
    pv: 680,
    amt: 1700,
  },
  {
    name: "juil",
    pv: 680,
    amt: 1700,
  },
  {
    name: "aoûl",
    pv: 680,
    amt: 1700,
  },
  {
    name: "sept",
    pv: 680,
    amt: 1700,
  },
  {
    name: "oct",
    pv: 680,
    amt: 1700,
  },
  {
    name: "nov",
    pv: 680,
    amt: 1700,
  },
  {
    name: "dec",
    pv: 680,
    amt: 1700,
  },
];

export default function BarChart() {
  return (
    <div className="dashboardChart1Wrapper" style={{ marginTop: "38px" }}>
      <p className="dashboardChart1P">
        Monthly subscriptions income
      </p>
      <div style={{ width: "100%" }}>
        <ResponsiveContainer width="100%" aspect={3}>
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid  horizontal="true" vertical="" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" barSize={10} radius={20} fill="#FF2600" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
