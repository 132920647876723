import * as actionTypes from "./activityActionType";
import axios from "axios";
import { baseUrl } from "../../config";
import { toast } from 'react-toastify';

export const getActivity = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(getActivityInit());
        axios.get(`${baseUrl}/activity`, data, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(getActivitySuccess(response.data.data));
            } else{
                dispatch(getActivityFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(getActivityFailure());
        });
    };
};

export const createActivity = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(createActivityInit());
        axios.post(`${baseUrl}/activity/store`, data, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                toast.success("create Successfully", {}); 
                dispatch(createActivitySuccess(response.data.data));
            } else{
                dispatch(createActivityFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(createActivityFailure());
        });
    };
};
export const editActivity = (id) => {
    return (dispatch) => {
        dispatch(editActivityInit());
        axios.get(`${baseUrl}/activity/${id}`, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(editActivitySuccess(response.data.data));
            } else{
                dispatch(editActivityFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(editActivityFailure());
        });
    };
};

export const updateActivity = (data) => {
    data.description = '';
    return (dispatch) => {
        dispatch(updateActivityInit());
        axios.put(`${baseUrl}/activity/${data.id}`, data)
        .then((response)=>{
            if(response.data.message === "Success"){
                toast.success("Update Successfully", {}); 
                dispatch(updateActivitySuccess(response.data.data));
                console.warn(response.data.data);
            } else{
                dispatch(updateActivityFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(updateActivityFailure());
        });
    };
};

export const deleteActivity = (id) => {
    console.log("id :---", id);
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(deleteActivityInit());
        axios.delete(`${baseUrl}/activity/${id}`, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                toast.success("Delete Successfully", {}); 
                dispatch(deleteActivitySuccess(response.data.data));
            } else{
                dispatch(deleteActivityFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(deleteActivityFailure());
        });
    };
};

export const createActivityInit = () => ({
  type: actionTypes.CREATE_ACTIVITY
});

export const createActivityFailure = data => ({
  type: actionTypes.CREATE_ACTIVITY_FAILURE
});

export const createActivitySuccess = data => ({
  type: actionTypes.CREATE_ACTIVITY_SUCCESS,
  payload: { data }
});

export const getActivityInit = () => ({
    type: actionTypes.ALL_ACTIVITY
  });
  
export const getActivityFailure = data => ({
    type: actionTypes.ALL_ACTIVITY_FAILURE
});

export const getActivitySuccess = data => ({
    type: actionTypes.ALL_ACTIVITY_SUCCESS,
    payload: { data }
});

export const editActivityInit = () => ({
    type: actionTypes.EDIT_ACTIVITY
  });
  
export const editActivityFailure = data => ({
    type: actionTypes.EDIT_ACTIVITY_FAILURE
});

export const editActivitySuccess = data => ({
    type: actionTypes.EDIT_ACTIVITY_SUCCESS,
    payload: { data }
});

export const updateActivityInit = () => ({
    type: actionTypes.UPDATE_ACTIVITY
  });
  
export const updateActivityFailure = data => ({
    type: actionTypes.UPDATE_ACTIVITY_FAILURE
});

export const updateActivitySuccess = data => ({
    type: actionTypes.UPDATE_ACTIVITY_SUCCESS,
    payload: { data }
});

export const deleteActivityInit = () => ({
    type: actionTypes.DELETE_ACTIVITY
  });
  
export const deleteActivityFailure = data => ({
    type: actionTypes.DELETE_ACTIVITY_FAILURE
});

export const deleteActivitySuccess = data => ({
    type: actionTypes.DELETE_ACTIVITY_SUCCESS,
    payload: { data }
});