import * as actionTypes from "./cguActionType";
import axios from "axios";
import { baseUrl } from "../../config";
import { toast } from 'react-toastify';

export const getCgu = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(getCguInit());
        axios.get(`${baseUrl}/cgu`, data, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(getCguSuccess(response.data.data));
            } else{
                dispatch(getCguFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(getCguFailure());
        });
    };
};

export const editCgu = (id) => {
    return (dispatch) => {
        dispatch(editCguInit());
        axios.get(`${baseUrl}/cgu/${id}`, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(editCguSuccess(response.data.data));
            } else{
                dispatch(editCguFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(editCguFailure());
        });
    };
};

export const updateCgu = (data) => {
    return (dispatch) => {
        dispatch(updateCguInit());
        axios.put(`${baseUrl}/cgu/${data.id}`, data)
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(updateCguSuccess(response.data.data));
                console.warn(response.data.data);
            } else{
                dispatch(updateCguFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(updateCguFailure());
        });
    };
};

export const getCguInit = () => ({
    type: actionTypes.ALL_CGU
  });
  
export const getCguFailure = data => ({
    type: actionTypes.ALL_CGU_FAILURE
});

export const getCguSuccess = data => ({
    type: actionTypes.ALL_CGU_SUCCESS,
    payload: { data }
});

export const editCguInit = () => ({
    type: actionTypes.EDIT_CGU
  });
  
export const editCguFailure = data => ({
    type: actionTypes.EDIT_CGU_FAILURE
});

export const editCguSuccess = data => ({
    type: actionTypes.EDIT_CGU_SUCCESS,
    payload: { data }
});

export const updateCguInit = () => ({
    type: actionTypes.UPDATE_CGU_FAILURE
  });
  
export const updateCguFailure = data => ({
    type: actionTypes.UPDATE_CGU_FAILURE
});

export const updateCguSuccess = data => ({
    type: actionTypes.UPDATE_CGU_SUCCESS,
    payload: { data }
});
