export const CREATE_HASHTAG = "CREATE_HASHTAG";
export const CREATE_HASHTAG_SUCCESS = "CREATE_HASHTAG_SUCCESS";
export const CREATE_HASHTAG_FAILURE = "CREATE_HASHTAG_FAILURE";

export const ALL_HASHTAG = "ALL_HASHTAG";
export const ALL_HASHTAG_SUCCESS = "ALL_HASHTAG_SUCCESS";
export const ALL_HASHTAG_FAILURE = "ALL_HASHTAG_FAILURE";

export const EDIT_HASHTAG = "EDIT_HASHTAG";
export const EDIT_HASHTAG_SUCCESS = "EDIT_HASHTAG_SUCCESS";
export const EDIT_HASHTAG_FAILURE = "EDIT_HASHTAG_FAILURE";

export const UPDATE_HASHTAG = "UPDATE_HASHTAG";
export const UPDATE_HASHTAG_SUCCESS = "UPDATE_HASHTAG_SUCCESS";
export const UPDATE_HASHTAG_FAILURE = "UPDATE_HASHTAG_FAILURE";

export const DELETE_HASHTAG = "DELETE_HASHTAG";
export const DELETE_HASHTAG_SUCCESS = "DELETE_HASHTAG_SUCCESS";
export const DELETE_HASHTAG_FAILURE = "DELETE_HASHTAG_FAILURE";