import ConfigrationPage from './configration.component';
import { connect } from 'react-redux';
import { getActivity, createActivity, editActivity, updateActivity, deleteActivity } from './../../api/activity/activityAction';
import { createHashtag, allHashtag, deleteHashtag, editHashtag, updateHashtag } from './../../api/hashtag/hashtagAction';
import { getObjective, createObjective, editObjective, updateObjective, deleteObjective } from './../../api/objective/objectiveAction';
import { getGift, deleteGift, editGift, createGift, updateGift } from './../../api/gift/giftAction';
import { getWallet, deleteWallet, editWallet, createWallet, updateWallet } from './../../api/wallet/walletAction';

const mapDispatchToProps = {
    createHashtag,
    allHashtag,
    editHashtag,
    updateHashtag,
    deleteHashtag,
    getActivity,
    createActivity,
    editActivity,
    updateActivity,
    deleteActivity,
    getObjective,
    createObjective,
    editObjective,
    updateObjective,
    deleteObjective,
    getGift,
    deleteGift,
    editGift,
    createGift,
    updateGift,
    getWallet,
    deleteWallet,
    editWallet,
    createWallet,
    updateWallet,
}

const mapStateToProps = state => ({
    isLoading: state.activityPage.isLoading,
    isError: state.userPage.isError,
    isLoggedIn: state.userPage.isLoggedIn,
    hashtags: state.hashtagPage.hashtags,
    count: state.hashtagPage.count,
    list: state.hashtagPage.list,
    isSaving: state.activityPage.isSaving,
    isObjective: state.objectivePage.isObjective,
    isHashtag: state.hashtagPage.isHashtag,
    activity: state.activityPage.activity,
    objectives: state.objectivePage.objectives,
    activityList: state.activityPage.activityList,
    objectiveList: state.objectivePage.objectiveList,
    gift: state.giftPage.gift,
    giftList: state.giftPage.giftList,
    isGift: state.giftPage.isGift,
    wallet: state.walletPage.wallet,
    walletList: state.walletPage.walletList,
    isWallet: state.walletPage.isWallet,
});

export const ConfigrationPageContainer = connect(mapStateToProps, mapDispatchToProps)(ConfigrationPage); 