import CoachPage from './coach.component';
import { connect } from 'react-redux';
import { fetchList, userOfficial } from './../../api/user/userAction';

const mapDispatchToProps = {
    fetchList,
    userOfficial
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isError: state.userPage.isError,
    users: state.userPage.users,
    count: state.userPage.count,
    detail: state.userPage.detail,
    official: state.userPage.official
});

export const CoachPageContainer = connect(mapStateToProps, mapDispatchToProps)(CoachPage); 