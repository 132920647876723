import CGVPage from './cgv.component';
import { connect } from 'react-redux';
import { getCgv, editCgv, updateCgv } from './../../api/cgv/cgvAction';

const mapDispatchToProps = {
    getCgv,
    editCgv,
    updateCgv
}

const mapStateToProps = state => ({
    isLoading: state.cgvPage.isLoading,
    isError: state.cgvPage.isError,
    isLoggedIn: state.cgvPage.isLoggedIn,
    isSaving: state.cgvPage.isSaving,
    cgv: state.cgvPage.cgv,
    cgvList: state.cgvPage.cgvList,
});

export const CGVPageContainer = connect(mapStateToProps, mapDispatchToProps)(CGVPage); 