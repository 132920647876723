export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const FETCH_LIST = "FETCH_LIST";
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "FETCH_LIST_FAILURE";

export const FETCH_USER_DETAIL = "FETCH_USER_DETAIL";
export const FETCH_USER_DETAIL_SUCCESS = "FETCH_USER_DETAIL_SUCCESS";
export const FETCH_USER_DETAIL_FAILURE = "FETCH_USER_DETAIL_FAILURE";

export const FETCH_OFFICIAL = "FETCH_OFFICIAL";
export const FETCH_OFFICIAL_SUCCESS = "FETCH_OFFICIAL_SUCCESS";
export const FETCH_OFFICIAL_FAILURE = "FETCH_OFFICIAL_FAILURE";

export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

export const USER_DELETE = "USER_DELETE";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";


export const MAIL_DELETE = "MAIL_DELETE";
export const MAIL_DELETE_SUCCESS = "MAIL_DELETE_SUCCESS";
export const MAIL_DELETE_FAILURE = "MAIL_DELETE_FAILURE";

export const FETCH_FITNESS_DETAIL = "FETCH_FITNESS_DETAIL";
export const FETCH_FITNESS_DETAIL_SUCCESS = "FETCH_FITNESS_DETAIL_SUCCESS";
export const FETCH_FITNESS_DETAIL_FAILURE = "FETCH_FITNESS_DETAIL_FAILURE";

export const FETCH_NUTRATION_DETAIL = "FETCH_NUTRATION_DETAIL";
export const FETCH_NUTRATION_DETAIL_SUCCESS = "FETCH_NUTRATION_DETAIL_SUCCESS";
export const FETCH_NUTRATION_DETAIL_FAILURE = "FETCH_NUTRATION_DETAIL_FAILURE";

export const FETCH_SHARE_FOOD_DETAIL = "FETCH_SHARE_FOOD_DETAIL";
export const FETCH_SHARE_FOOD_DETAIL_SUCCESS = "FETCH_SHARE_FOOD_DETAIL_SUCCESS";
export const FETCH_SHARE_FOOD_DETAIL_FAILURE = "FETCH_SHARE_FOOD_DETAIL_FAILURE";


export const FETCH_SHARE_WORKOUT_DETAIL = "FETCH_SHARE_WORKOUT_DETAIL";
export const FETCH_SHARE_WORKOUT_DETAIL_SUCCESS = "FETCH_SHARE_WORKOUT_DETAIL_SUCCESS";
export const FETCH_SHARE_WORKOUT_DETAIL_FAILURE = "FETCH_SHARE_WORKOUT_DETAIL_FAILURE";

export const ALL_ACTIVITY = "ALL_ACTIVITY";
export const ALL_ACTIVITY_SUCCESS = "ALL_ACTIVITY_SUCCESS";
export const ALL_ACTIVITY_FAILURE = "ALL_ACTIVITY_FAILURE";


export const ALL_OBJECTIVE = "ALL_OBJECTIVE";
export const ALL_OBJECTIVE_SUCCESS = "ALL_OBJECTIVE_SUCCESS";
export const ALL_OBJECTIVE_FAILURE = "ALL_OBJECTIVE_FAILURE";

export const ALL_STATUS_REQUEST = "ALL_STATUS_REQUEST";
export const ALL_STATUS_REQUEST_SUCCESS = "ALL_STATUS_REQUEST_SUCCESS";
export const ALL_STATUS_REQUEST_FAILURE = "ALL_STATUS_REQUEST_FAILURE";

