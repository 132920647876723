import * as actionTypes from "./walletActionType";

const initialState = {
    isLoading: false,
    isError: false,
    isWallet: false,
    isLoggedIn: localStorage.getItem("admin_access_token") !== null ? true : false,
    loggedUser: localStorage.getItem("admin_details") !== null ? JSON.parse(localStorage.getItem("admin_details")) : {},
    wallet: [],
    walletList: {},
    count: 0
    
}

export const walletReducer = (state = initialState, action) => {
    switch (action.type) {   
        case actionTypes.ALL_WALLET:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isWallet: false,
            };
        case actionTypes.ALL_WALLET_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isWallet: false,
            };
        case actionTypes.ALL_WALLET_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isWallet: true,
                wallet: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : [],
            };
        case actionTypes.CREATE_WALLET:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isWallet: false
            };
        case actionTypes.CREATE_WALLET_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isWallet: false
            };
        case actionTypes.CREATE_WALLET_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isWallet: true
            };  
        case actionTypes.EDIT_WALLET:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isWallet: false
            };
        case actionTypes.EDIT_WALLET_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isWallet: false
            };
        case actionTypes.EDIT_WALLET_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isWallet: true,
                walletList: action.payload.data,
            }; 
        case actionTypes.UPDATE_WALLET:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isWallet: false
            };
        case actionTypes.UPDATE_WALLET_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isWallet: false
            };
        case actionTypes.UPDATE_WALLET_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isWallet: true,
            }; 
        case actionTypes.DELETE_WALLET:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isWallet: false
            };
        case actionTypes.DELETE_WALLET_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isWallet: false
            };
        case actionTypes.DELETE_WALLET_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isWallet: true
            };    
        default: 
            return state
    }
}