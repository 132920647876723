import React from "react";
import { Link } from "react-router-dom";
// react bootstarp
import Table from "react-bootstrap/Table";
import { useEffect, useState, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Form from "react-bootstrap/Form";
// component
import SidebarPage from "./../../component/sidebar";
// img
import teams from "./../../img/not-available-circle.png";
import team from "./../../img/team.jpg";
import logo from "./../../img/logo.png";
import Image from "react-bootstrap/Image";
import MyPagination from "../configration/MyPagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CoachPage(props) {
  const { fetchList, users, userOfficial, official } = props;

  useEffect(() => {
    fetchList({});
  }, []);

  const [open, setOpen] = useState(false);

  const[selectedValue, setSelectedValue] = useState("");

  const appHeader = () => {
    setOpen(true);
  };

  const onHandle = (val, parms) => {
    userOfficial(val, parms);
    setSelectedValue(val, parms);
  }

  useEffect(() => {
    if(official) {
      fetchList({});
    }
  }, [official]);

  useEffect(() => {
    if (selectedValue !== "") {
      toast.success("Success Message");
    }
  }, [selectedValue]);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(10);
 
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;

    const paginate = useCallback((currentPage) => {
      setCurrentPage(currentPage)
    }, [])

    //search filter
    const [search, setSearch] = React.useState("");

    const handleSearchChange = (e) => {
      setCurrentPage(1);
      setSearch(e.target.value);
    };

    const filtered = !search
    ? users
    : users.filter((person) =>
      person.firstName ? person.firstName.toLowerCase().includes(search.toLowerCase() ) : ''
    );
    
    const [popoverData, setPopoverData] = useState(null);
    const handleRowClick = (item) => {
      console.log("utem",item)
      // Set the popoverData state with the dynamic data for the clicked row (item)
      setPopoverData(item);
     
    };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
      <div onClick={() => document.body.click()} className="text-end">   <span  className="close">&times;</span></div>
        <div className="coachUserDate">
   
          <div>
            <img src={popoverData?.image} className="tableImg1" alt="" />
          </div>
          <div style={{ display: "flex" }}>
            <div className="coachUserRaring">
              <i class="fa-solid fa-thumbs-up usersIcon"></i>
              <p className="usersText">{popoverData?.is_workout_like} K</p>
              <p className="usersText1">Likes</p>
            </div>
            <div className="coachUserRaring">
              <i class="fa-solid fa-users usersIcon"></i>
              <p className="usersText">{popoverData?.is_follower} K</p>
              <p className="usersText1">followers</p>
            </div>
            <div className="coachUserRaring">
              <i class="fa-solid fa-star usersIcon"></i>
              <p className="usersText">4.8/5</p>
              <p className="usersText1">rating</p>
            </div>
          </div>
        </div>
        <div>
          <p className="userDataName">{popoverData?.firstName} {popoverData?.lastName}</p>
          <p className="userInfo">{popoverData?.activity_type} </p>
          {/* <p className="userInfo">Olympic champion / gold </p>
          <p className="userInfo">Professional boxer</p> */}
          
    
          <p className="userDataName1 mt-4">
            Bio
            <br />
            {popoverData?.bio}
          </p>
        </div>
      </Popover.Body>
    </Popover>
  );
  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <>
      <div className="mobile_header" >
        <div className={open ? "mobile_righticon" : "iconText" }>
          <i class="fa fa-list" onClick={appHeader} >
            {open ? <SidebarPage open={open} /> : <img src={logo} className="mobile_logo" alt="" />}
          </i>
        </div>
      </div>
      <div className="rightSection">
        <div
          style={{
            textAlign: "right",
            marginTop: "22px",
            marginBottom: "17px",
          }}
        >
          <p className="userListTop">
            <i>Cliquez pour les détails</i>
          </p>
        </div>
        <div className="divWrapperCoach">
          <Table className="tableBody tableBodyCoachTable">
            <thead>
              <tr className="tableTR tableTRSearch">
                <th></th>
                <th className="tableHeadTxt"></th>
                <th className="tableHeadTxt"></th>
                <th className="tableHeadTxt"></th>
                <th className="tableHeadTxt" style={{ width: "18%" }}>
                  <Form className="d-flex searchBoxs">
                    <Form.Control
                      value={search}
                      type="search"
                      onChange={handleSearchChange}
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <i className="fa-solid fa-search searchText"></i>
                  </Form>
                </th>
              </tr>
              <tr className="tableTR">
                <th></th>
                <th className="tableHeadTxt" width="20%">Name</th>
                <th className="tableHeadTxt">Description</th>
                <th className="tableHeadTxt">Status </th>
                <th className="tableHeadTxt text-center">Type</th>
              </tr>
            </thead>
            <tbody>
              {filtered && Array.isArray(filtered) && filtered
                .slice(indexOfFirstPost, indexOfLastPost)
                .map((item) => (
                  <tr key={item.id} className="tableTR">
                  <td className="tableTxt"  onClick={() => {
                        console.log("item"); // Check the item data
                        handleRowClick(item);
                      }}>
                    <OverlayTrigger 
                      trigger="click"
                      rootClose
                      placement="bottom"
                      overlay={popover}
                     
                    >
                      {item.image ? 
                        <Image className="tableImg" alt="" src={item.image} />
                        : <Image className="tableImg" src={teams} alt="" />
                      }
                    </OverlayTrigger>
                    
                  </td>
                  <td className="tableTxt"  onClick={() => {
                        console.log("item"); // Check the item data
                        handleRowClick(item);
                      }}>
                   {/* <OverlayTrigger  
                      trigger="click"
                      placement="bottom"
                      rootClose
                      overlay={popover}
                     
                    >*/}
                    { item.firstName  ? <span>
                      <div className="linkUserText" >{item.firstName} {item.lastName} </div>  
                      </span> : 'N/A' 
                    } 
                    {/* </OverlayTrigger>      */}
                  </td>
                  <td className="tableTxt" >
                    { item.description ? <span>
                      <div className="linkUserText" >{item.description} </div>  
                      </span> : 'N/A'  
                    }      
                  </td>
                  <td className="tableTxt">
                    { item.phone ? <span> {item.phone}  </span> : 'N/A' }  
                  </td>
                  <td class="text-center">
                
                     <Button onClick={() =>  onHandle(item.id, item.is_official === 0 ? 1 : 0)} className="simpleBtn accepté">
                       {item.is_official === 0 ? "Refuse" : "Accept"}
                    </Button>
                  </td>
                </tr>
              ))}
              <tr className="tableTR">
                <td></td>
                <td className="tableTR">
                  <b class="mt-3">Showing {currentPage}-{postPerPage} of {filtered.length}  users</b>
                </td>
                <td className="tableTxt"></td>
                <td className="tableTxt"></td>
                <td>
                  <MyPagination
                    size="sm"
                    postPerPage={postPerPage}
                    currentPage={currentPage}
                    paginate={paginate}
                    totalPosts={filtered.length}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
