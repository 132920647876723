import StatusRequestPage from './statusrequest.component';
import { connect } from 'react-redux';
import { fetchListStatus, updateUser, deleteUser,sendMail } from '../../api/user/userAction';

const mapDispatchToProps = {
    fetchListStatus,
    updateUser,
    deleteUser,
    sendMail
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isError: state.userPage.isError,
    users: state.userPage.users,
    count: state.userPage.count
});

export const StatusRequestPageContainer = connect(mapStateToProps, mapDispatchToProps)(StatusRequestPage);