export const ALL_CGV = "ALL_CGV";
export const ALL_CGV_SUCCESS = "ALL_CGV_SUCCESS";
export const ALL_CGV_FAILURE = "ALL_CGV_FAILURE";

export const EDIT_CGV = "EDIT_CGV";
export const EDIT_CGV_SUCCESS = "EDIT_CGV_SUCCESS";
export const EDIT_CGV_FAILURE = "EDIT_CGV_FAILURE";

export const UPDATE_CGV = "UPDATE_CGV";
export const UPDATE_CGV_SUCCESS = "UPDATE_CGV_SUCCESS";
export const UPDATE_CGV_FAILURE = "UPDATE_CGV_FAILURE";