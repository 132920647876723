export const CREATE_WALLET = "CREATE_WALLET";
export const CREATE_WALLET_SUCCESS = "CREATE_WALLET_SUCCESS";
export const CREATE_WALLET_FAILURE = "CREATE_WALLET_FAILURE";

export const ALL_WALLET = "ALL_WALLET";
export const ALL_WALLET_SUCCESS = "ALL_WALLET_SUCCESS";
export const ALL_WALLET_FAILURE = "ALL_WALLET_FAILURE";

export const EDIT_WALLET = "EDIT_WALLET";
export const EDIT_WALLET_SUCCESS = "EDIT_WALLET_SUCCESS";
export const EDIT_WALLET_FAILURE = "EDIT_WALLET_FAILURE";

export const UPDATE_WALLET = "UPDATE_WALLET";
export const UPDATE_WALLET_SUCCESS = "UPDATE_WALLET_SUCCESS";
export const UPDATE_WALLET_FAILURE = "UPDATE_WALLET_FAILURE";

export const DELETE_WALLET = "DELETE_WALLET";
export const DELETE_WALLET_SUCCESS = "DELETE_WALLET_SUCCESS";
export const DELETE_WALLET_FAILURE = "DELETE_WALLET_FAILURE";