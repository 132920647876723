import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const MyHashtagPagination = ({ totalPosts, hashtagPaginate, currentHashtagPage, postPerPageHashtag }) => {
    const pageNumbers = [];
    
    if(currentHashtagPage >= 1) {
        pageNumbers.push(<Pagination.Prev key="prev" onClick={() => hashtagPaginate(currentHashtagPage - 1)} />)
    }

    for(let page = 1; page <= Math.ceil(totalPosts / postPerPageHashtag); page++) {
        pageNumbers.push(
            <Pagination.Item key={page} data-page={page} active={page === currentHashtagPage}  onClick={() => hashtagPaginate(page)} >
                {page}
            </Pagination.Item>
        )
    }

    if (currentHashtagPage <= 1) {
        pageNumbers.push(<Pagination.Next key="next" onClick={() => hashtagPaginate(currentHashtagPage + 1)} />)
    }

    return (
        <>
            <Pagination size="sm" style={{float: "right"}} className='pagenationBtn'>{pageNumbers}</Pagination>
        </>
        
    )
}

export default MyHashtagPagination;
