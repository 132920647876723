import * as actionTypes from "./giftActionType";
import axios from "axios";
import { baseUrl } from "../../config";
import { toast } from 'react-toastify';

export const getGift = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(getGiftInit());
        axios.get(`${baseUrl}/gift`, data, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(getGiftSuccess(response.data.data));
            } else{
                dispatch(getGiftFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(getGiftFailure());
        });
    };
};

export const createGift = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(createGiftInit());
        axios.post(`${baseUrl}/gift/store`, data, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(createGiftSuccess(response.data.data));
            } else{
                dispatch(createGiftFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(createGiftFailure());
        });
    };
};
export const editGift = (id) => {
    return (dispatch) => {
        dispatch(editGiftInit());
        axios.get(`${baseUrl}/gift/${id}`, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(editGiftSuccess(response.data.data));
            } else{
                dispatch(editGiftFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(editGiftFailure());
        });
    };
};

export const updateGift = (data) => {
    const params = new FormData();
    params.append("title",data.title);
    params.append("icon",data.icon);
    params.append("amount",data.amount);
    console.warn('data:- ',params)
    return (dispatch) => {
        dispatch(updateGiftInit());
        axios.put(`${baseUrl}/gift/${data.id}`, params)
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(updateGiftSuccess(response.data.data));
                console.warn(response.data.data);
            } else{
                dispatch(updateGiftFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(updateGiftFailure());
        });
    };
};

export const deleteGift = (id) => {
    console.log("id :---", id);
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(deleteGiftInit());
        axios.delete(`${baseUrl}/gift/${id}`, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(deleteGiftSuccess(response.data.data));
            } else{
                dispatch(deleteGiftFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(deleteGiftFailure());
        });
    };
};

export const createGiftInit = () => ({
  type: actionTypes.CREATE_GIFT
});

export const createGiftFailure = data => ({
  type: actionTypes.CREATE_GIFT_FAILURE
});

export const createGiftSuccess = data => ({
  type: actionTypes.CREATE_GIFT_SUCCESS,
  payload: { data }
});

export const getGiftInit = () => ({
    type: actionTypes.ALL_GIFT
  });
  
export const getGiftFailure = data => ({
    type: actionTypes.ALL_GIFT_FAILURE
});

export const getGiftSuccess = data => ({
    type: actionTypes.ALL_GIFT_SUCCESS,
    payload: { data }
});

export const editGiftInit = () => ({
    type: actionTypes.EDIT_GIFT
  });
  
export const editGiftFailure = data => ({
    type: actionTypes.EDIT_GIFT_FAILURE
});

export const editGiftSuccess = data => ({
    type: actionTypes.EDIT_GIFT_SUCCESS,
    payload: { data }
});

export const updateGiftInit = () => ({
    type: actionTypes.UPDATE_GIFT
  });
  
export const updateGiftFailure = data => ({
    type: actionTypes.UPDATE_GIFT_FAILURE
});

export const updateGiftSuccess = data => ({
    type: actionTypes.UPDATE_GIFT_SUCCESS,
    payload: { data }
});

export const deleteGiftInit = () => ({
    type: actionTypes.DELETE_GIFT
  });
  
export const deleteGiftFailure = data => ({
    type: actionTypes.DELETE_GIFT_FAILURE
});

export const deleteGiftSuccess = data => ({
    type: actionTypes.DELETE_GIFT_SUCCESS,
    payload: { data }
});