export const CREATE_OBJECTIVE = "CREATE_OBJECTIVE";
export const CREATE_OBJECTIVE_SUCCESS = "CREATE_OBJECTIVE_SUCCESS";
export const CREATE_OBJECTIVE_FAILURE = "CREATE_OBJECTIVE_FAILURE";

export const ALL_OBJECTIVE = "ALL_OBJECTIVE";
export const ALL_OBJECTIVE_SUCCESS = "ALL_OBJECTIVE_SUCCESS";
export const ALL_OBJECTIVE_FAILURE = "ALL_OBJECTIVE_FAILURE";

export const EDIT_OBJECTIVE = "EDIT_OBJECTIVE";
export const EDIT_OBJECTIVE_SUCCESS = "EDIT_OBJECTIVE_SUCCESS";
export const EDIT_OBJECTIVE_FAILURE = "EDIT_OBJECTIVE_FAILURE";

export const UPDATE_OBJECTIVE = "UPDATE_OBJECTIVE";
export const UPDATE_OBJECTIVE_SUCCESS = "UPDATE_OBJECTIVE_SUCCESS";
export const UPDATE_OBJECTIVE_FAILURE = "UPDATE_OBJECTIVE_FAILURE";

export const DELETE_OBJECTIVE = "DELETE_OBJECTIVE";
export const DELETE_OBJECTIVE_SUCCESS = "DELETE_OBJECTIVE_SUCCESS";
export const DELETE_OBJECTIVE_FAILURE = "DELETE_OBJECTIVE_FAILURE";