import ListOfUsersPage from './listOfUsers.component';
import { connect } from 'react-redux';
import { fetchList } from './../../api/user/userAction';

const mapDispatchToProps = {
    fetchList
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isError: state.userPage.isError,
    users: state.userPage.users,
    count: state.userPage.count
});

export const ListOfUsersPageContainer = connect(mapStateToProps, mapDispatchToProps)(ListOfUsersPage);