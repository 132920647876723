import UserDetailsPage from './userDetails.component';
import { connect } from 'react-redux';
import { userdetail } from '../../api/user/userAction';
import { getSubscription ,getPaidSubscription ,getCancelSubscription ,getPaymentMethod} from '../../api/subscription/subscriptionAction';
import { userOfficial,updateUser,deleteUser,fitnessdetail ,nutritionDetail,shareworkoutDetail ,getObjective,shareFoodDetail,getActivityAll} from '../../api/user/userAction';

const mapDispatchToProps = {
    userdetail,
    userOfficial,
    updateUser,
    deleteUser,
    fitnessdetail,
    nutritionDetail,
    shareworkoutDetail,
    shareFoodDetail,
    getActivityAll,
    getObjective,
    getSubscription,
    getPaidSubscription,
    getCancelSubscription,
    getPaymentMethod
    
    
}
 
const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isError: state.userPage.isError,
    isDelete : state.userPage.isDelete,
    detail: state.userPage.detail,
    Official: state.userPage.Official,
    fitness:state.userPage.fitness,
    nutration_data:state.userPage.nutration_data,
    share_workout_data:state.userPage.share_workout_data,
    share_food_data:state.userPage.share_food_data,
    activity:state.userPage.activity,
    objectives:state.userPage.objectives,
    subscription_data:state.subscriptionPage.subscription_data,
    subscriptionPaid_data:state.subscriptionPage.subscriptionPaid_data,
    isDeleteSubscription:state.subscriptionPage.isDeleteSubscription,
    payment_method_data: state.subscriptionPage.payment_method_data
});


export const UserDetailsPageContainer = connect(mapStateToProps, mapDispatchToProps)(UserDetailsPage); 