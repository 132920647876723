import React from "react";
// react bootstarp
import Table from "react-bootstrap/Table";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
// import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
// component
import SidebarPage from "../../component/sidebar";
// img
import team from "./../../img/not-available-circle.png";
import logo from "./../../img/logo.png";
import MyPagination from "./MyPagination";
import Image from "react-bootstrap/Image";
import { BackgroundImage } from "@mantine/core";

export default function StatusRequestPage(props) {
  const navigate = useNavigate();
  const { fetchListStatus, users, count, updateUser, deleteUser, sendMail } = props;
  useEffect(() => {
    fetchListStatus({});
  }, []);

  const [open, setOpen] = React.useState(false);


  const appHeader = () => {
    setOpen(true);
  };

  //pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postPerPage] = React.useState(10);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;

  const paginate = React.useCallback((currentPage) => {
    setCurrentPage(currentPage)
  }, [])


  //search filter
  const [search, setSearch] = React.useState("");

  const handleSearchChange = (e) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const filtered = !search
    ? users
    : users.filter((person) => person.firstName ? person.firstName.toLowerCase().includes(search.toLowerCase()) : []);


  const changeOfficial = (id, val) => {
    const data = {
      id: id,
      is_official: val,
      user_role: "official"
    }
    updateUser(data);
    setTimeout(() => {
      fetchListStatus({});
    }, 2000);

  }

  const refuse = (id, email) => {
    sendMail(email)

    console.warn('id:- ', id)
    if (id) {
      setTimeout(() => {
        deleteUser(id)

        window.location.reload();
      }, 2000);
    }



  }
  // console.log("filtered.length", filtered.length)

  return (
    <>
      <div className="mobile_header" >
        <div className={open ? "mobile_righticon" : "iconText"}>
          <i class="fa fa-list" onClick={appHeader} >
            {open ? <SidebarPage open={open} /> : <img src={logo} className="mobile_logo" alt="" />}
          </i>
        </div>
      </div>
      <div className="rightSection">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "22px",
            marginBottom: "17px",
          }}
        >
          {/* <div>
            <Form className="d-flex searchBoxs">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <i className="fa-solid fa-search searchText"></i>
            </Form>
          </div> */}
          {/* <div>
            <p className="userListTop">
              <i>Cliquez pour les détails</i>
            </p>
          </div> */}
        </div>
        <div className="tableScrollDiv">
          <Table className="tableBody tableScroll">
            <thead>
              <tr className="tableTR tableTRSearch">
                <th></th>
                <th className="tableHeadTxt"></th>
                <th className="tableHeadTxt"></th>
                <th className="tableHeadTxt"></th>
                <th className="tableHeadTxt" colspan="5" style={{ width: "18%" }}>
                  {filtered && Array.isArray(filtered) && filtered.length > 0 &&
                    <Form className="d-flex searchBoxs">
                      <Form.Control
                        value={search}
                        type="search"
                        onChange={handleSearchChange}
                        placeholder="Search"
                        className="me-2"
                        aria-label="Search"
                      />
                      <i className="fa-solid fa-search searchText"></i>
                    </Form>
                  }
                </th>
              </tr>
              <tr className="tableTR">
                <th></th>
                <th className="tableHeadTxt">Name</th>
                <th className="tableHeadTxt">Activity</th>
                <th className="tableHeadTxt">Description</th>
                <th className="tableHeadTxt">Status</th>
                <th className="tableHeadTxt">Action</th>
              </tr>
            </thead>
            <tbody>
              {filtered && Array.isArray(filtered) && filtered.length > 0 ? (
                filtered.slice(indexOfFirstPost, indexOfLastPost)
                  .map((item) => (

                    <tr key={item.id} className="tableTR">
                      <td>
                        {item.image ?
                          <Image className="tableImg" alt="" src={item.image} />
                          : <Image className="tableImg" src={team} alt="" />
                        }
                      </td>
                      <td className="tableTxt">
                        {item.firstName ? <span>
                          <div onClick={() => navigate(`/user-details/${item.id}`)} className="linkUserText" >{item.firstName} {item.lastName} </div>
                        </span> : 'N/A'
                        }
                      </td>
                      <td className="tableTxt">{item.activity_type ? item.activity_type : '-'}</td>
                      <td className="tableTxt">
                        {<span>
                          <div onClick={() => navigate(`/user-details/${item.id}`)} className="linkUserText" >{item.description ? item.description : '-'} </div>
                        </span>
                        }
                      </td>
                      <td className="tableTxt">{item.phone}</td>
                      <td>

                        {

                          item.is_official == 2 ?
                            <Button className="simpleBtn">Official</Button>
                            : <Button className="simpleBtn mb-2" onClick={(e) => changeOfficial(item.id, 2)}>Accept</Button>
                        }
                        <Button className="refuseBtn" onClick={(e) => refuse(item.id, item.email)}>Refuse</Button>{" "}
                      </td>
                    </tr>
                  ))
              ) : (
                // Render a "No Data Found" row when filtered array is empty
                <tr>
                  <td colSpan="6" className="text-center">No Data Found</td>
                </tr>
              )}
              {filtered && Array.isArray(filtered) && filtered.length > 0 &&
                < tr className="tableTR">
                  <td></td>
                  <td className="tableTR">
                    {
                      count <= 10 ?
                        <b class="mt-3">Showing {filtered.length}  users</b>
                        : <b class="mt-3">Showing {currentPage} - {postPerPage} of {filtered.length}  users</b>
                    }

                  </td>
                  <td className="tableTxt"></td>
                  <td className="tableTxt"></td>
                  <td>
                    <MyPagination
                      size="sm"
                      postPerPage={postPerPage}
                      currentPage={currentPage}
                      paginate={paginate}
                      totalPosts={filtered.length}
                    />
                  </td>
                </tr>
              }
            </tbody>
          </Table>
        </div>
      </div >
    </>
  );
}