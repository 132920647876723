import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const MyWalletPagination = ({ totalPosts, walletPaginate, currentWalletPage, postPerPagewallet }) => {
    const pageNumbers = [];
    if(currentWalletPage > 1) {
        pageNumbers.push(<Pagination.Prev key="prev" onClick={() => walletPaginate(currentWalletPage - 1)} />)
    }

    for(let page = 1; page <= Math.ceil(totalPosts / postPerPagewallet); page++) {
        pageNumbers.push(
            <Pagination.Item key={page} data-page={page} active={page === currentWalletPage} onClick={() => walletPaginate(page)}>
                {page}
            </Pagination.Item>
        )
    }

    if (currentWalletPage <= 0) {
        pageNumbers.push(<Pagination.Next key="next" onClick={() => walletPaginate(currentWalletPage + 1)} />)
    }

    return (
        <>
            <Pagination size="sm" style={{float: "right"}}>{pageNumbers}</Pagination>
        </>
        
    )
}

export default MyWalletPagination;