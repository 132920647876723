import * as actionTypes from "./walletActionType";
import axios from "axios";
import { baseUrl } from "../../config";
import { toast } from 'react-toastify';

export const getWallet = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(getWalletInit());
        axios.get(`${baseUrl}/wallet`, data, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(getWalletSuccess(response.data.data));
            } else{
                dispatch(getWalletFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(getWalletFailure());
        });
    };
};

export const createWallet = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(createWalletInit());
        axios.post(`${baseUrl}/wallet/store`, data, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(createWalletSuccess(response.data.data));
            } else{
                dispatch(createWalletFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(createWalletFailure());
        });
    };
};
export const editWallet = (id) => {
    return (dispatch) => {
        dispatch(editWalletInit());
        axios.get(`${baseUrl}/wallet/${id}`, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(editWalletSuccess(response.data.data));
            } else{
                dispatch(editWalletFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(editWalletFailure());
        });
    };
};

export const updateWallet = (data) => {
    return (dispatch) => {
        dispatch(updateWalletInit());
        axios.put(`${baseUrl}/wallet/${data.id}`, data)
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(updateWalletSuccess(response.data.data));
                console.warn(response.data.data);
            } else{
                dispatch(updateWalletFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(updateWalletFailure());
        });
    };
};

export const deleteWallet = (id) => {
    console.log("id :---", id);
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(deleteWalletInit());
        axios.delete(`${baseUrl}/wallet/${id}`, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(deleteWalletSuccess(response.data.data));
            } else{
                dispatch(deleteWalletFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(deleteWalletFailure());
        });
    };
};

export const createWalletInit = () => ({
  type: actionTypes.CREATE_WALLET
});

export const createWalletFailure = data => ({
  type: actionTypes.CREATE_WALLET_FAILURE
});

export const createWalletSuccess = data => ({
  type: actionTypes.CREATE_WALLET_SUCCESS,
  payload: { data }
});

export const getWalletInit = () => ({
    type: actionTypes.ALL_WALLET
  });
  
export const getWalletFailure = data => ({
    type: actionTypes.ALL_WALLET_FAILURE
});

export const getWalletSuccess = data => ({
    type: actionTypes.ALL_WALLET_SUCCESS,
    payload: { data }
});

export const editWalletInit = () => ({
    type: actionTypes.EDIT_WALLET
  });
  
export const editWalletFailure = data => ({
    type: actionTypes.EDIT_WALLET_FAILURE
});

export const editWalletSuccess = data => ({
    type: actionTypes.EDIT_WALLET_SUCCESS,
    payload: { data }
});

export const updateWalletInit = () => ({
    type: actionTypes.UPDATE_WALLET
  });
  
export const updateWalletFailure = data => ({
    type: actionTypes.UPDATE_WALLET_FAILURE
});

export const updateWalletSuccess = data => ({
    type: actionTypes.UPDATE_WALLET_SUCCESS,
    payload: { data }
});

export const deleteWalletInit = () => ({
    type: actionTypes.DELETE_WALLET
  });
  
export const deleteWalletFailure = data => ({
    type: actionTypes.DELETE_WALLET_FAILURE
});

export const deleteWalletSuccess = data => ({
    type: actionTypes.DELETE_WALLET_SUCCESS,
    payload: { data }
});