import { combineReducers } from 'redux';
import { userReducer as userPage } from './../api/user/userReducer';
import { hashtagReducer as hashtagPage } from './../api/hashtag/hashtagReducer';
import { activityReducer as activityPage } from './../api/activity/activityReducer';
import { objectiveReducer as objectivePage } from './../api/objective/objectiveReducer';
import { cgvReducer as cgvPage } from './../api/cgv/cgvReducer';
import { cguReducer as cguPage } from './../api/cgu/cguReducer';
import { giftReducer as giftPage } from './../api/gift/giftReducer';
import { walletReducer as walletPage } from './../api/wallet/walletReducer';
import { subscriptionReducer as subscriptionPage } from './../api/subscription/subscriptionReducer';

const appReducer = combineReducers({
    userPage,
    hashtagPage,
    activityPage,
    objectivePage,
    cgvPage,
    cguPage,
    giftPage,
    walletPage,
    subscriptionPage
});

export default appReducer;