import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
  } from "recharts";
  import "./../index.css";
  
  export default function LineChart1() {
    const data = [
      {
        name: "5 j",
        uv: 4000,
        pv: 2400,
        amt: 2400,
      },
      {
        name: "10 j",
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: "15 j",
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: "20 j",
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: "25 j",
        uv: 1890,
        pv: 4800,
        amt: 2181,
      },
      {
        name: "30 j",
        uv: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
    ];
    return (
      <div className="dashboardChart1Wrapper" style={{ marginTop: "38px" }}>
        <p className="dashboardChart1P">Number of subscriptions per day over 1 month</p>
        <div className="dashboardChart1">
        <ResponsiveContainer width="100%" aspect={3}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="0 0" horizontal="true" vertical=""/>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#FF2600"
              activeDot={{ r: 8 }}
              strokeWidth={4}
            />
            {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
          </LineChart>
        </ResponsiveContainer>
        </div>
      </div>
    );
  }
  