import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const MyGiftPagination = ({ totalPosts, giftPaginate, currentGiftPage, postPerPagegift }) => {
    const pageNumbers = [];
    if(currentGiftPage > 1) {
        pageNumbers.push(<Pagination.Prev key="prev" onClick={() => giftPaginate(currentGiftPage - 1)} />)
    }

    for(let page = 1; page <= Math.ceil(totalPosts / postPerPagegift); page++) {
        pageNumbers.push(
            <Pagination.Item key={page} data-page={page} active={page === currentGiftPage} onClick={() => giftPaginate(page)}>
                {page}
            </Pagination.Item>
        )
    }

    if (currentGiftPage <= 0) {
        pageNumbers.push(<Pagination.Next key="next" onClick={() => giftPaginate(currentGiftPage + 1)} />)
    }

    return (
        <>
            <Pagination size="sm" style={{float: "right"}}>{pageNumbers}</Pagination>
        </>
        
    )
}

export default MyGiftPagination;