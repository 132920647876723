import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
// import Modal from "react-bootstrap/Modal";
//img
import logo from "./../../img/logo.png";
// component
import SidebarPage from "./../../component/sidebar";
import { Link } from "react-router-dom";

export default function SupportPage() {
  const [page, setPage] = React.useState(1);
  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const handelChange = React.useCallback(
    (page) => {
      setPage(page)
    },
    [],
  )

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("totally custom!")
    );

    return <p onClick={decoratedOnClick}>{children}</p>;
  }

  const [open, setOpen] = React.useState(false);

  const appHeader = () => {
    setOpen(true);
  };
  
  return (
    <>
      <div className="mobile_header" >
        <div className={open ? "mobile_righticon" : "iconText" }>
          <i class="fa fa-list" onClick={appHeader} >
            {open ? <SidebarPage open={open} /> : <img src={logo} className="mobile_logo" alt="" />}
          </i>
        </div>
      </div>
      <div className="rightSection">
        <div style={{ textAlign: "right" }}>
          <p className="supportTitle">
            <i>Cliquez pour les détails</i>
          </p>
        </div>
        <div className="divWrapper">
          <div style={{ display: "flex", width: "500px" }}>
            <Link to="/support" className="actUnder">
              <div className="actBox">
                <p>Active tickets</p>
              </div>
            </Link>
            <Link to="/ticket-resolve" className="actUnder">
              <div className="resBox">
                <p>Resolved tickets</p>
              </div>
            </Link>
          </div>
          <Table className="tableBody1 supportTableBackground">
            <thead>
              <tr className="tableTR">
                <th className="tableHeadTxt1">
                  <i class="fa-regular fa-star"></i>
                </th>
                <th className="tableHeadTxt1">N</th>
                <th className="tableHeadTxt1">Title</th>
                <th className="tableHeadTxt1">Status</th>
                <th className="tableHeadTxt1">Priority</th>
              </tr>
            </thead>
            <tbody>
              <tr className="tableTR">
                <td>
                  <i class="fa-solid fa-star"></i>
                </td>
                <td className="tableTxt1">15</td>
                <td className="tableTxt1">
                  <Accordion defaultActiveKey="1">
                    <CustomToggle eventKey="0">
                      <p className="tableTxt1">
                        New sidebar - creater new system group...error making
                        http - to be res...
                      </p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="tableTxt111">
                            <i>Deadline :</i> 12/04 / 2023
                          </p>
                          <p className="tableTxt112" eventKey="0">
                            Hide
                          </p>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                </td>
                <td className="tableTxt1 progressCls">In&nbsp;progress</td>
                <td className="tableTxt1 urgrntCls">Urgent</td>
              </tr>
              <tr className="tableTR">
                <td>
                  <i class="fa-solid fa-star"></i>
                </td>
                <td className="tableTxt1">15</td>
                <td className="tableTxt1">
                  <Accordion defaultActiveKey="1">
                    <CustomToggle eventKey="0">
                      <p className="tableTxt1">
                        New sidebar - creater new system group...error making
                        http - to be res...
                      </p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="tableTxt111">
                            <i>Deadline :</i> 12/04 / 2023
                          </p>
                          <p className="tableTxt112" eventKey="0">
                            Hide
                          </p>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                </td>
                <td className="tableTxt1 closed">Closed</td>
                <td className="tableTxt1 standerdCls">Standard</td>
              </tr>
              <tr className="tableTR">
                <td>
                  <i class="fa-solid fa-star"></i>
                </td>
                <td className="tableTxt1">15</td>
                <td className="tableTxt1">
                  <Accordion defaultActiveKey="1">
                    <CustomToggle eventKey="0">
                      <p className="tableTxt1">
                        New sidebar - creater new system group...error making
                        http - to be res...
                      </p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="tableTxt111">
                            <i>Deadline :</i> 12/04 / 2023
                          </p>
                          <p className="tableTxt112" eventKey="0">
                            Hide
                          </p>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                </td>
                <td className="tableTxt1 panding">Pending</td>
                <td className="tableTxt1 urgrntCls">Urgent</td>
              </tr>
              <tr className="tableTR">
                <td>
                  <i class="fa-solid fa-star"></i>
                </td>
                <td className="tableTxt1">15</td>
                <td className="tableTxt1">
                  <Accordion defaultActiveKey="1">
                    <CustomToggle eventKey="0">
                      <p className="tableTxt1">
                        New sidebar - creater new system group...error making
                        http - to be res...
                      </p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="tableTxt111">
                            <i>Deadline :</i> 12/04 / 2023
                          </p>
                          <p className="tableTxt112" eventKey="0">
                            Hide
                          </p>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                </td>
                <td className="tableTxt1 progressCls">In&nbsp;progress</td>
                <td className="tableTxt1 urgrntCls">Urgent</td>
              </tr>
              <tr className="tableTR">
                <td>
                  <i class="fa-solid fa-star"></i>
                </td>
                <td className="tableTxt1">15</td>
                <td className="tableTxt1">
                  <Accordion defaultActiveKey="1">
                    <CustomToggle eventKey="0">
                      <p className="tableTxt1">
                        New sidebar - creater new system group...error making
                        http - to be res...
                      </p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="tableTxt111">
                            <i>Deadline :</i> 12/04 / 2023
                          </p>
                          <p className="tableTxt112" eventKey="0">
                            Hide
                          </p>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                </td>
                <td className="tableTxt1 panding">Pending</td>
                <td className="tableTxt1 urgrntCls">Urgent</td>
              </tr>
              <tr className="tableTR">
                <td>
                  <i class="fa-solid fa-star"></i>
                </td>
                <td className="tableTxt1">15</td>
                <td className="tableTxt1">
                  <Accordion defaultActiveKey="1">
                    <CustomToggle eventKey="0">
                      <p className="tableTxt1">
                        New sidebar - creater new system group...error making
                        http - to be res...
                      </p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="tableTxt111">
                            <i>Deadline :</i> 12/04 / 2023
                          </p>
                          <p className="tableTxt112" eventKey="0">
                            Hide
                          </p>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                </td>
                <td className="tableTxt1 panding">Pending</td>
                <td className="tableTxt1 urgrntCls">Urgent</td>
              </tr>
              <tr className="tableTR">
                <td>
                  <i class="fa-solid fa-star"></i>
                </td>
                <td className="tableTxt1">15</td>
                <td className="tableTxt1">
                  <Accordion defaultActiveKey="1">
                    <CustomToggle eventKey="0">
                      <p className="tableTxt1">
                        New sidebar - creater new system group...error making
                        http - to be res...
                      </p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="tableTxt111">
                            <i>Deadline :</i> 12/04 / 2023
                          </p>
                          <p className="tableTxt112" eventKey="0">
                            Hide
                          </p>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                </td>
                <td className="tableTxt1 panding">Pending</td>
                <td className="tableTxt1 urgrntCls">Urgent</td>
              </tr>
              <tr className="tableTR">
                <td>
                  <i class="fa-solid fa-star"></i>
                </td>
                <td className="tableTxt1">15</td>
                <td className="tableTxt1">
                  <Accordion defaultActiveKey="1">
                    <CustomToggle eventKey="0">
                      <p className="tableTxt1">
                        New sidebar - creater new system group...error making
                        http - to be res...
                      </p>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <p className="tableTxt111">
                          <i>Assigned by :</i>Wofich
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="tableTxt111">
                            <i>Deadline :</i> 12/04 / 2023
                          </p>
                          <p className="tableTxt112" eventKey="0">
                            Hide
                          </p>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                </td>
                <td className="tableTxt1 panding">Pending</td>
                <td className="tableTxt1 urgrntCls">Urgent</td>
              </tr>
              <tr className="tableTR">
                <td></td>
                <td className="tableTR"></td>
                <td className="tableTxt1"></td>
                <td className="tableTxt1"></td>
                <td style={{ display: "flex" }}>
                  <i class="fa-solid fa-angle-left rtlBtn"></i>
                  <div>
                    <Pagination size="sm" onChnage={handelChange} page={page}>
                      {items}
                    </Pagination>
                  </div>
                  <i class="fa-solid fa-angle-right rtlBtn"></i>
                </td>
              </tr>
            </tbody>
            {/* <div>
              <div>
                <p className="tableTxt">
                  <i>Showing 1-8 of 160 users</i>
                </p>
              </div>
              <div>
              <i class="fa-solid fa-angle-left"></i>
                  <span>
                    <ul style={{ listStyleType: "none" }}>
                        <li>1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                        <li>5</li>
                    </ul>
                  </span>
                  <i class="fa-solid fa-angle-right"></i>
              </div>
            </div> */}
          </Table>
        </div>
      </div>
    </>
  );
}
