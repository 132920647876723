import DashboardPage from './dashboard.component';
import { connect } from 'react-redux';
import { fetchList } from './../../api/user/userAction';
import { allHashtag } from './../../api/hashtag/hashtagAction';

const mapDispatchToProps = {
    fetchList,
    allHashtag
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isError: state.userPage.isError,
    users: state.userPage.users,
    count: state.userPage.count,
    hashtags: state.hashtagPage.hashtags,
});

export const DashboardPageContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
