import * as actionTypes from "./hashtagActionType";

const initialState = {
    isLoading: false,
    isError: false,
    isHashtag: false,
    isLoggedIn: localStorage.getItem("admin_access_token") !== null ? true : false,
    loggedUser: localStorage.getItem("admin_details") !== null ? JSON.parse(localStorage.getItem("admin_details")) : {},
    hashtags: [],
    list: {},
    count: 0
    
}

export const hashtagReducer = (state = initialState, action) => {
    switch (action.type) {   
        case actionTypes.ALL_HASHTAG:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isHashtag: false
            };
        case actionTypes.ALL_HASHTAG_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isHashtag: false
            };
        case actionTypes.ALL_HASHTAG_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isHashtag: true,
                hashtags: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : [],
            };
        case actionTypes.CREATE_HASHTAG:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isHashtag: false
            };
        case actionTypes.CREATE_HASHTAG_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isHashtag: false
            };
        case actionTypes.CREATE_HASHTAG_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isHashtag: true
            };  
        case actionTypes.EDIT_HASHTAG:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isHashtag: false
            };
        case actionTypes.EDIT_HASHTAG_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isHashtag: false
            };
        case actionTypes.EDIT_HASHTAG_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isHashtag: true,
                list: action.payload.data,
            }; 
        case actionTypes.UPDATE_HASHTAG:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isHashtag: false
            };
        case actionTypes.UPDATE_HASHTAG_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isHashtag: false
            };
        case actionTypes.UPDATE_HASHTAG_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isHashtag: true,
            }; 
        case actionTypes.DELETE_HASHTAG:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isHashtag: false
            };
        case actionTypes.DELETE_HASHTAG_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isHashtag: false
            };
        case actionTypes.DELETE_HASHTAG_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isHashtag: true
            };    
        default: 
            return state
    }
}