import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// react bootstarp
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { Container, Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// component
import SidebarPage from "./../../component/sidebar";
// img
import logo from "./../../img/logo.png";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
export default function UserDetailsPage(props) {
  const { id } = useParams();
  const navigate = useNavigate()

  const { userdetail, detail, userOfficial, Official, updateUser, getCancelSubscription, isDelete, deleteUser, fitnessdetail, fitness, nutritionDetail, nutration_data, shareworkoutDetail, shareFoodDetail, share_workout_data, share_food_data, getActivityAll, activity, getObjective, objectives, getSubscription, subscription_data, getPaidSubscription, subscriptionPaid_data, payment_method_data, getPaymentMethod } = props;

  const [isclicked, setIsclicked] = React.useState(false);
  const [expandedRows, setExpandedRows] = React.useState([]);
  const [expandState, setExpandState] = React.useState({});
  const [da, setDa] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const handleEpandRow = (event, userId) => {
    console.warn("hsgchghcs", event);
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);
    setIsclicked(true);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };

  const [open, setOpen] = React.useState(false);

  const appHeader = () => {
    setOpen(true);
  };

  const [selectedDiv, setSelectedDiv] = useState(null);
  const [divId, setDivId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true); // Set loading to true before fetching data
        if (id) {
          await userdetail(id);
          resetForm();


        }
        await fitnessdetail(id);
        await nutritionDetail(id);
        await shareworkoutDetail(id);
        await shareFoodDetail(id);
        await getActivityAll();
        await getObjective();
        await getSubscription(id);
        await getPaidSubscription(id);
        await getPaymentMethod(id);
        setSelectedDiv(1)
        setDivId(1)
      } catch (error) {
        // Handle error, e.g., show an error message
        setIsLoading(false);
      } finally {
        setIsLoading(false); // Set loading to false when data is fetched or in case of an error
      }
    }

    fetchData();
  }, [
    fitnessdetail,
    nutritionDetail,
    shareworkoutDetail,
    shareFoodDetail,
    getActivityAll,
    getObjective,
    getSubscription,
    getPaidSubscription,
    getPaymentMethod,
    id,
    userdetail,
  ]);


  // useEffect(() => {
  //   fitnessdetail();
  // }, [fitnessdetail]);


  // useEffect(() => {
  //   nutritionDetail();
  // }, [nutritionDetail]);

  // useEffect(() => {
  //   shareworkoutDetail();
  // }, [shareworkoutDetail]);

  // useEffect(() => {
  //   shareFoodDetail();
  // }, [shareFoodDetail]);


  // useEffect(() => {
  //   getActivityAll();
  // }, [getActivityAll]);


  // useEffect(() => {
  //   getObjective();
  // }, [getObjective]);


  // useEffect(() => {
  //   getSubscription(id);
  // }, [getSubscription]);

  // useEffect(() => {
  //   getPaidSubscription(id);
  // }, [getPaidSubscription]);

  // useEffect(() => {
  //   getPaymentMethod(id);
  // }, [getPaymentMethod]);


  // useEffect(() => {
  //   if (id) {
  //     userdetail(id);
  //     resetForm()
  //   }
  // }, [id]);





  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    if (selectedValue !== "") {
      toast.success("Success Message");
    }
  }, [selectedValue]);

  const handleChange = (event) => {
    userOfficial(event.target.value);
    setSelectedValue(event.target.value);
  };

  const changeStatus = (id, val) => {
    const updateStatus = { "id": id, "status": val };
    updateUser(updateStatus);
    console.warn('changeStatus callling....', updateStatus);
  }


  const deleteSingleUser = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteUser(id);
            setTimeout(() => {
              navigate('/list-of-users');
            }, 2000);
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  //const [showAll, setShowAll] = useState(false);
  // let objectiveArray;
  // let initialRowCount;
  // let displayedObjectives;
  // if (detail.user_objective) {
  //   objectiveArray = detail.user_objective.split(',').map(item => item.trim());
  //   console.log("objectiveArray", objectiveArray)
  //   initialRowCount = 2;
  //   displayedObjectives = showAll ? objectiveArray : objectiveArray.slice(0, initialRowCount);
  // }

  // Define the number of rows to initially display

  let user_objective =  detail.user_objective;

  console.log("user_objective",detail.user_objective)
  let objectiveArray=[];
  let  initialRowCount = 3;
  if(user_objective){
    console.log("user_objective",user_objective)
    try{
      objectiveArray = user_objective.split(',').map(item => item.trim());
    }catch{

    }
      // Set your initial row count
  }
  
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };



  let user_prefered_sport =  detail.user_prefered_sport;
  console.log("user_prefered_sport",detail.user_prefered_sport)
  let sportArray=[];
  let  initialRowSportCount = 3;
  if(user_prefered_sport){
    console.log("user_prefered_sport",user_prefered_sport)
    try{
      sportArray = user_prefered_sport.split(',').map(item => item.trim());
    }catch{

    }
      // Set your initial row count
  }
  
  const [showAllSport, setShowAllSport] = useState(false);

  const toggleShowAllSport = () => {
    setShowAllSport(!showAllSport);
  };


  const [showActive, setShowActive] = useState(false);


  const handleClose = () => {

    setShowActive(false);
    resetForm()
  };

  const resetForm = () => {
    setName(detail.firstName);
    setlName(detail.lastName);
    setEmail(detail.email);
    setActivity(detail.activity_type);
    setPhone(detail.phone);
  };

  const handleShow = () => {

    setTimeout(() => {
      setShowActive(true);
      resetForm()
    }, 1000);
  }


  // Edit User
  // console.log("detail Edit User ", detail.firstName)
  const [name, setName] = useState(detail.firstName != undefined ? detail.firstName : '');
  const [lname, setlName] = useState(detail.lastName != undefined ? detail.lastName : '');
  const [email, setEmail] = useState(detail.email);
  // const [activityOptions, setActivityOptions] = useState([]);
  const [activity_type, setActivity] = useState('');
  const [phone, setPhone] = useState(detail.phone);


  // Validation state variables
  const [nameError, setNameError] = useState('');
  const [lnameError, setLNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [activityError, setActivityError] = useState('');
  const [phoneError, setPhoneError] = useState('');



  const Cancelsubscription = (id, index) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure , you want to Cancel subscription ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setTimeout(() => {
              getCancelSubscription(id);
              getSubscription(id);
            }, 1000);
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }
  const handleEdit = async () => {
    // Use the captured values for your EditUser function
    // Validation logic
    if (!name) {
      setNameError('First name is required.');
      return;
    } else {
      setNameError('');
    }

    if (!lname) {
      setLNameError('Last name is required.');
      return;
    } else {
      setLNameError('');
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError('Email is required.');
      return;
    } else if (!emailPattern.test(email)) {
      setEmailError('Invalid email format.');
      return;
    } else {
      setEmailError('');
    }

    if (!activity_type) {
      setActivityError('Please select an activity.');
      return;
    } else {
      setActivityError('');
    }

    if (!phone) {
      setPhoneError('Phone number is required.');
      return;
    } else {
      setPhoneError('');
    }


    const data = {
      id: id,
      firstName: name,
      lastName: lname,
      email,
      activity_type,
      phone
    }
    updateUser(data)
    toast.success("User Update Success");
    setTimeout(() => {
      handleClose();
      userdetail(id)
    }, 1000);


  };


  const divs = [
    { id: 1, content: 'Fitness' },
    { id: 2, content: 'Nutrition' },
    { id: 3, content: 'Share My Food' },
    { id: 4, content: 'Share My Workout' }
  ];





  const handleDivClick = async (divId) => {
    console.log("divId", divId)
    setDivId(divId)
    setSelectedDiv(divId)
    setExpandedRows1([]);
  }


  const [expandedRows1, setExpandedRows1] = useState([]);

  const handleExpandRow = (index) => {
    // Toggle the expanded state for the clicked row.
    if (expandedRows1.includes(index)) {
      setExpandedRows1(expandedRows1.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows1([...expandedRows1, index]);
    }
  };



  return (
    <div style={{
      width: "100%"
    }}>
      {isLoading ? (
        <div className="loader-container">
          <Oval
            height={100}
            width={100}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}

          />
        </div>
      ) : (
        <>
          <div className="mobile_header">
            <div className={open ? "mobile_righticon" : "iconText"}>
              <i class="fa fa-list" onClick={appHeader}>
                {open ? (
                  <SidebarPage open={open} />
                ) : (
                  <img src={logo} className="mobile_logo" alt="" />
                )}
              </i>
            </div>
          </div>
          <div className="rightSection">
            {/* <div className="Srchbox">
          <Form className="d-flex searchBoxs">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <i className="fa-solid fa-search searchText"></i>
          </Form>
        </div> */}
            <div className="detailsTopDivHead">
              <div className="detailTop">
                <p className="detailsTopDivP">
                  {detail.firstName && detail.lastName
                    ? detail.firstName + " " + detail.lastName
                    : detail.firstName
                      ? detail.firstName
                      : "N/A"}
                  {/* <p>
                    {detail.RoleId === 1 ? (
                      <span>User</span>
                    ) : (
                      <span>Nutrition</span>
                    )}
                  </p> */}
                </p>
                <div className="detailHead">
                  <Button variant="link" className="modButton" onClick={handleShow}>
                    modify
                  </Button>
                  <Button onClick={() => deleteSingleUser()} variant="link" className="deleButton">
                    Delete
                  </Button>
                  {
                    detail.status == 1 ? <Button onClick={() => changeStatus(detail.id, 0)} variant="link" className="deactButton">
                      Deactivate
                    </Button> : <Button onClick={() => changeStatus(detail.id, 1)} variant="link" className="deactButton">
                      Activate
                    </Button>
                  }

                </div>
              </div>
              <div>
                <p className="detailsTopDivP1">Personal information :  </p>
                <div>
                  <p className="detailsTopDivP1">{detail.description}</p>
                </div>
                <div className="dTDiv3">
                  <p>
                    Date of registration on Wofich : &nbsp;
                    <span>
                      <Moment format="DD/MM/YYYY">{detail.createdAt}</Moment>
                    </span>
                  </p>
                  <Form.Group style={{ display: "flex" }}>
                    <Form.Label className="selectUser">User Category:</Form.Label>
                    <Form.Label className="selectUser"> {detail.is_official == 2 ? "Official" : "Simple User"}</Form.Label>
                  </Form.Group>
                  {/* <div>
                <Form.Group style={{ display: "flex" }}>
                  <Form.Label className="selectUser">User Official:</Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    aria-label="Default select example"
                    className="dropBtns"
                  >
                    <option>Select Official</option>
                    <option selected={detail.is_official === 1} value="1">
                      Yes
                    </option>
                    <option selected={detail.is_official === 0} value="0">
                      No
                    </option>
                  </Form.Select>
                </Form.Group>
              </div> */}
                </div>
              </div>
            </div>
            {/* </div>
        </div> */}
            <div className="divWrapper">
              <div className="coachWrapper">
                <p>List of user's coaches :</p>
                <Table className="tableBody1">
                  <thead>
                    <tr className="tableTR">
                      <th className="tableHeadTxt">Coach</th>
                      <th className="tableHeadTxt">Date of subscriptions</th>
                      <th className="tableHeadTxt">Options</th>
                      <th className="tableHeadTxt">Total amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscription_data.length != 0 ? (
                      subscription_data.map((subscription, index) => (
                        <tr className="tableTR">
                          <td className="tableTxt">{subscription?.subscriber_details?.User?.firstName} {subscription?.subscriber_details?.User?.lastName}</td>
                          <td className="tableTxt"> {new Date(subscription?.subscriber_details?.createdAt).toLocaleDateString()}</td>
                          <td className="tableTxt">{subscription?.paid_options?.rows[0].Paid_Option.title}</td>
                          <td className="tableTxt">{subscription?.subscriber_details?.total_amount} €</td>
                          <td>
                            <Button variant="link" onClick={() => Cancelsubscription(subscription?.subscriber_details?.id, index)}
                              className="refuserBtn">
                              Cancel subscription
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td style={{ textAlign: 'center' }} colSpan="6">No Subscription data available.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>

            {detail.user_role != 'SimpleUser' && subscriptionPaid_data.length != 0 && (
              <div className="divWrapper">
                <div className="coachWrapper">
                  <p>List of paying options :</p>
                  <Table className="tableBody1">
                    <thead>
                      <tr className="tableTR">
                        <th className="tableHeadTxt">Option</th>
                        <th className="tableHeadTxt">Price</th>
                        <th className="tableHeadTxt"></th>
                        <th className="tableHeadTxt"></th>
                        <th className="tableHeadTxt"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptionPaid_data.length != 0 ? (
                        subscriptionPaid_data.map((paying, index) => (
                          <tr className="tableTR">
                            <td className="tableTxt">{paying.title}</td>
                            <td className="tableTxt">{paying.price} €</td>
                            <td className="tableTxt"></td>
                            <td className="tableTxt"></td>
                            <td className="tableTxt"></td>
                            <td></td>
                            {/* <td>
                      <Button variant="link" className="modirefuserBtn">
                        Modify
                      </Button>
                      <Button variant="link" className="refuserBtn">
                        Delete
                      </Button>
                    </td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td style={{ textAlign: 'center' }} colSpan="6">No Paying data available.</td>
                        </tr>
                      )}

                    </tbody>
                  </Table>
                </div>
              </div>
            )}
            <div className="divWrapper">
              <div className="studentsWrapper">
                <p>List of posts</p>
                <div
                  className="badgeListUser"
                  style={{ display: "flex", marginTop: "40px" }}
                >
                  {divs.map(div => (

                    <div className="">
                      <p key={div.id}
                        className={`badgeList badgeListP ${selectedDiv === div.id ? 'selected' : ''}`}
                        onClick={() => handleDivClick(div.id)} >{div.content}</p>
                    </div>

                  ))}
                </div>

                {divId == 1 && (
                  <Table className="tableBody2">
                    <thead>
                      <tr className="tableTR">
                        <th className="tableHeadTxt">Title</th>
                        <th className="tableHeadTxt">Type</th>
                        <th className="tableHeadTxt">Total Session Time</th>
                        <th className="tableHeadTxt">Account</th>

                      </tr>
                    </thead>
                    <tbody>
                      {fitness.length != 0 ? (
                        fitness.map((fitness, index) => (
                          <React.Fragment key={index}>
                            <tr className="tableTR" style={{ borderBottom: "2px solid #feeeea00" }}>
                              <td className={`tableTxt ${isclicked ? "blueColorProgram" : "tableTxt"}`}
                                onClick={() => handleExpandRow(index)}>
                                {fitness.title}
                              </td>
                              <td className="pt-18">
                                {fitness.fitness_type}
                              </td>
                              <td className="pt-18">
                                {fitness.total_session_time}
                              </td>
                              <td className="pt-18">
                                {fitness.account}
                              </td>
                            </tr>
                            {expandedRows1.includes(index) && (
                              <tr>
                                <td colSpan="12">
                                  <div
                                    style={{
                                      color: "#000000",
                                      padding: "10px",
                                      textAlign: "left",
                                      marginLeft: "103px",
                                    }}
                                  >
                                    <p className="programDrop">
                                      <i>{fitness.account}</i>
                                    </p>
                                    <p className="programDrop">
                                      Type : <span>{fitness.fitness_type}</span>
                                    </p>
                                    <p className="programDrop">
                                      Duration : <span>{fitness.total_session_time}</span>
                                    </p>
                                    <p className="programDrop">
                                      Short Discription : <span>{fitness.short_description}</span>
                                    </p>

                                    <p className="programDrop">
                                      Level Difficulty : <span>{fitness.level_difficulty}</span>
                                    </p>


                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No fitness data available.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}

                {divId == 2 && (
                  <Table className="tableBody2">
                    <thead>
                      <tr className="tableTR">
                        <th className="tableHeadTxt">Title</th>
                        <th className="tableHeadTxt">Is Likes</th>
                        <th className="tableHeadTxt">Receipe Added</th>
                        <th className="tableHeadTxt">Total Likes</th>

                      </tr>
                    </thead>
                    <tbody>
                      {nutration_data.length != 0 ? (
                        nutration_data.map((nutrition, index) => (
                          <React.Fragment key={index}>
                            <tr className="tableTR" style={{ borderBottom: "2px solid #feeeea00" }}>
                              <td className={`tableTxt ${isclicked ? "blueColorProgram" : "tableTxt"}`}
                                onClick={() => handleExpandRow(index)}>
                                {nutrition.title}
                              </td>
                              <td className="pt-18">
                                {nutrition.is_like}
                              </td>
                              <td className="pt-18">
                                {nutrition.receipe_added}
                              </td>
                              <td className="pt-18">
                                {nutrition.totalLikes}
                              </td>
                            </tr>
                            {expandedRows1.includes(index) && (
                              <tr>
                                <td colSpan="12">
                                  <div
                                    style={{
                                      color: "#000000",
                                      padding: "10px",
                                      textAlign: "left",
                                      marginLeft: "103px",
                                    }}
                                  >

                                    <p className="programDrop">
                                      kcal : <span>{nutrition.kcal}</span>
                                    </p>

                                    <p className="programDrop">
                                      Receipe Added : <span>{nutrition.receipe_added}</span>
                                    </p>
                                    <p className="programDrop">
                                      Is Likes : <span>{nutrition.is_like}</span>
                                    </p>
                                    <p className="programDrop">
                                      Discription : <span>{nutrition.description}</span>
                                    </p>

                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No Nutrition data available.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}

                {divId == 3 && (
                  <Table className="tableBody2">
                    <thead>
                      <tr className="tableTR">
                        <th className="tableHeadTxt">Title</th>

                        <th className="tableHeadTxt">Category</th>
                        <th className="tableHeadTxt">Total Likes</th>

                      </tr>
                    </thead>
                    <tbody>
                      {share_food_data.length != 0 ? (
                        share_food_data.map((shareFood, index) => (
                          <React.Fragment key={index}>
                            <tr className="tableTR" style={{ borderBottom: "2px solid #feeeea00" }}>
                              <td className={`tableTxt ${isclicked ? "blueColorProgram" : "tableTxt"}`}
                                onClick={() => handleExpandRow(index)}>
                                {shareFood.title}
                              </td>
                              <td className="pt-18">
                                {shareFood.category}
                              </td>

                              <td className="pt-18">
                                {shareFood.total_like}
                              </td>
                            </tr>
                            {expandedRows1.includes(index) && (
                              <tr>
                                <td colSpan="12">
                                  <div
                                    style={{
                                      color: "#000000",
                                      padding: "10px",
                                      textAlign: "left",
                                      marginLeft: "103px",
                                    }}
                                  >

                                    <p className="programDrop">
                                      Title : <span>{shareFood.title}</span>
                                    </p>

                                    <p className="programDrop">
                                      Category : <span>{shareFood.category}</span>
                                    </p>
                                    <p className="programDrop">
                                      Total Likes : <span>{shareFood.total_like}</span>
                                    </p>
                                    <p className="programDrop">
                                      Discription : <span>{shareFood.description}</span>
                                    </p>

                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No  My Food Data available.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}

                {divId == 4 && (
                  <Table className="tableBody2">
                    <thead>
                      <tr className="tableTR">
                        <th className="tableHeadTxt">Title</th>

                        <th className="tableHeadTxt">Category</th>
                        <th className="tableHeadTxt">Total Likes</th>

                      </tr>
                    </thead>
                    <tbody>
                      {share_workout_data.length != 0 ? (
                        share_workout_data.map((MyWorkoutData, index) => (
                          <React.Fragment key={index}>
                            <tr className="tableTR" style={{ borderBottom: "2px solid #feeeea00" }}>
                              <td className={`tableTxt ${isclicked ? "blueColorProgram" : "tableTxt"}`}
                                onClick={() => handleExpandRow(index)}>
                                {MyWorkoutData.title}
                              </td>

                              <td className="pt-18">
                                {MyWorkoutData.category}
                              </td>
                              <td className="pt-18">
                                {MyWorkoutData.total_like}
                              </td>
                            </tr>
                            {expandedRows1.includes(index) && (
                              <tr>
                                <td colSpan="12">
                                  <div
                                    style={{
                                      color: "#000000",
                                      padding: "10px",
                                      textAlign: "left",
                                      marginLeft: "103px",
                                    }}
                                  >

                                    <p className="programDrop">
                                      Title : <span>{MyWorkoutData.title}</span>
                                    </p>

                                    <p className="programDrop">
                                      Category : <span>{MyWorkoutData.category}</span>
                                    </p>
                                    <p className="programDrop">
                                      Total Likes : <span>{MyWorkoutData.total_like}</span>
                                    </p>
                                    <p className="programDrop">
                                      Discription : <span>{MyWorkoutData.description}</span>
                                    </p>

                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6">No  My Workout Data available.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>

            <div className="divWrapper">
              <div className="listOfObjective">
                <p className="listOfObjectiveTitle">List of objective :</p>
                {objectiveArray.length === 0 ? (
                  <p className="listOfObjective1">No objectives to display.</p>
                ) : (
                  <>
                    {objectiveArray.slice(0, showAll ? objectiveArray.length : initialRowCount).map((objective, index) => (
                      <p className="listOfObjective1" key={index}>
                        {objective}
                      </p>
                    ))}
                    {objectiveArray.length > initialRowCount && (
                      <p className="text-end">
                        <button className="activityBtns border-0 bg-transparent" onClick={toggleShowAll}>
                          {showAll ? 'Show Less' : 'Show More'}
                        </button>
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>


            <div className="divWrapper">
              <div className="listOfObjective">
                <p className="listOfObjectiveTitle">List of Favourite Sport :</p>
                {sportArray.length === 0 ? (
                  <p className="listOfObjective1">No sport to display.</p>
                ) : (
                  <>
                    {sportArray.slice(0, showAll ? sportArray.length : initialRowSportCount).map((objective, index) => (
                      <p className="listOfObjective1" key={index}>
                        {objective}
                      </p>
                    ))}
                    {sportArray.length > initialRowSportCount && (
                      <p className="text-end">
                        <button className="activityBtns border-0 bg-transparent" onClick={toggleShowAll}>
                          {showAll ? 'Show Less' : 'Show More'}
                        </button>
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* <div className="divWrapper">
          <div className="listOfObjectiveMain">
            <p className="listOfObjectiveTitle">List of favourite sports :</p>
            <p className="listOfObjective1">Yoga</p>
            <div className="listOfObjectiveDiv">
              <p className="listOfObjective1">Tennis</p>
              <p className="listOfObjective2">Show more</p>
            </div>
          </div>
        </div> */}
            {/* <div className="divWrapper">
          <div className="incomeMainDiv">
            <p className="incomeMainDivTitle">Income</p>
            <Container>
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <div className="incomeMainDiv1">
                    <p className="incomeMainP">
                      Subscription income for the current month :{" "}
                      <span>XX €</span>
                    </p>
                    <p className="incomeMainP incomeP2">
                      Gift income for the current month : <span>€XX</span>
                    </p>
                    <p className="incomeMainP">
                      Total earnings for the current month : <span>XX €</span>
                    </p>
                  </div>
                  <div className="incomeMainDiv2">
                    <div className="incomeMainData">
                      <p className="incomeMainDataTitle">Last payment :</p>
                      <div>
                        <p className="incomeMainDate">12/02/2022</p>
                        <p className="incomeMainDate dateP1">XX €</p>
                        <p className="incomeMainDate dateP1">(ID :ABCD123)</p>
                      </div>
                    </div>
                    <div className="incomeMainData">
                      <p className="incomeMainDataTitle">Next payment :</p>
                      <div>
                        <p className="incomeMainDate">12/02/2022</p>
                        <p className="incomeMainDate  dateP1">XX €</p>
                        <p className="incomeMainDate  dateP1">(ID :ABCD123)</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <div className="makeTransferMain">
                    <p className="makeTransferMainTitle">Make transfer :</p>
                    <p className="makeTransferMainInput">
                      <i>XX</i>
                      <span> €</span>
                    </p>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <Button className="makeTransferMainBtn">Confirm</Button>{" "}
                    </div>
                    <p className="makeTransferMainText">
                      By clicking on "Confirm", the amount entered is sent
                      directly to the user's account.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="divWrapper">
          <div className="walletMainDiv">
            <p className="walletMainDivTitle">Wallet</p>
            <p className="walletMainDivTitle1">Amount : XX €</p>
            <div className="walletMainDiv2">
              <p className="walletMainDiv2Title">Recharge history</p>
              <div className="walletMainDivDateDiv">
                <p className="walletMainDivDate">12 /02/ 2012</p>
                <p className="walletMainDivDate">XX €</p>
              </div>
              <div className="walletMainDivDateDiv">
                <p className="walletMainDivDate">12 /02/ 2012</p>
                <p className="walletMainDivDate">XX €</p>
              </div>
              <div style={{ textAlign: "right" }}>
                <p className="walletMainDiv2Show">Show more</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "25px",
              }}
            >
              <Button className="makeTransferMainBtn">Reimburse</Button>{" "}
            </div>
          </div>
        </div>
        <div className="transferMainDiv">
          <p className="transferMainTitle">Transfer</p>
          <Container fluid>
            <Row>
              <Col sm={12} md={12} lg={5}>
                <div className="transferMainDiv1">
                  <p className="transferMainDiv1Title">
                    History of gifts given
                  </p>
                  <div className="divWrapper">
                    <Table className="tableBody2">
                      <thead>
                        <tr className="tableTR borderBot">
                          <th className="tableHeadTxt transList">Amount</th>
                          <th className="tableHeadTxt transList">Date</th>
                          <th
                            className="tableHeadTxt transList"
                            style={{ textAlign: "right" }}
                          >
                            Beneficiary Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="tableTR borderBot">
                          <td className="tableTxt transList1">XX €</td>
                          <td className="tableTxt transList1">12/02/2020</td>
                          <td
                            className="tableTxt transList1"
                            style={{ textAlign: "right" }}
                          >
                            Ana Taylor
                          </td>
                        </tr>
                        <tr className="tableTR borderBot">
                          <td className="tableTxt transList1">XX €</td>
                          <td className="tableTxt transList1">12/02/2020</td>
                          <td
                            className="tableTxt transList1"
                            style={{ textAlign: "right" }}
                          >
                            Ana Taylor
                          </td>
                        </tr>
                        <tr className="tableTR borderBot">
                          <td className="tableTxt transList1">XX €</td>
                          <td className="tableTxt transList1">12/02/2020</td>
                          <td
                            className="tableTxt transList1"
                            style={{ textAlign: "right" }}
                          >
                            Ana Taylor
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <p className="transferShow">Show more</p>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} lg={7}>
                <div className="transferMainDiv1">
                  <p className="transferMainDiv1Title">
                    History of gifts given
                  </p>
                  <div className="divWrapper">
                    <Table className="tableBody2">
                      <thead>
                        <tr className="tableTR borderBot">
                          <th className="tableHeadTxt transList">Amount</th>
                          <th className="tableHeadTxt transList">Date</th>
                          <th className="tableHeadTxt transList">
                            ID beneficiary
                          </th>
                          <th className="tableHeadTxt transList">
                            ID beneficiary
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="tableTR borderBot">
                          <td className="tableTxt transList1">XX €</td>
                          <td className="tableTxt transList1">12/02/2020</td>
                          <td className="tableTxt transList1">ABCD123</td>
                          <td className="tableTxt transList1">ABCD123</td>
                        </tr>
                        <tr className="tableTR borderBot">
                          <td className="tableTxt transList1">XX €</td>
                          <td className="tableTxt transList1">12/02/2020</td>
                          <td className="tableTxt transList1">ABCD123</td>
                          <td className="tableTxt transList1">ABCD123</td>
                        </tr>
                        <tr className="tableTR borderBot">
                          <td className="tableTxt transList1">XX €</td>
                          <td className="tableTxt transList1">12/02/2020</td>
                          <td className="tableTxt transList1">ABCD123</td>
                          <td className="tableTxt transList1">ABCD123</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <p className="transferShow">Show more</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}

            <div className="paymentMethodMain">
              <p className="paymentMethodMainTitle">Payment Methods</p>
              {payment_method_data.length != 0 ? (
                payment_method_data.map((payment_method, index) => (
                  <div className="paymentMethodMainDiv">
                    <p className="paymentMethodMainDivP1">{payment_method.card_name}</p>
                    <p className="paymentMethodMainDivP2">{payment_method.card_number}</p>
                  </div>
                ))
              ) : (
                <div className="paymentMethodMainDiv">
                  <p style={{ textAlign: 'center' }} className="paymentMethodMainDivP1">No Payment Method data available.</p>
                </div>
              )}
            </div>

          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Modal
            className="activeDivModalBox"
            show={showActive}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title><span class="modalBoxTitle">Edit User</span></Modal.Title>
            </Modal.Header>
            <Modal.Body closeButton>
              <div className="form-group mb-2">
                <label className="mb-1">First Name</label>
                <input
                  type="text"
                  value={name}
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                />
                <div className="text-danger">{nameError}</div>
              </div>

              <div className="form-group mb-2">
                <label className="mb-1">Last Name</label>
                <input
                  type="text"
                  value={lname}
                  className="form-control"
                  onChange={(e) => setlName(e.target.value)}
                />
                <div className="text-danger">{lnameError}</div>
              </div>

              <div className="form-group mb-2">
                <label htmlFor="email" className="mb-1">Email</label>
                <input
                  type="email"
                  value={email}
                  className="form-control"
                  readOnly='true'
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="text-danger">{emailError}</div>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="activity" className="mb-1">Activity</label>
                <select
                  className="form-select"
                  onChange={(e) => setActivity(e.target.value)}
                  value={activity_type}
                >
                  <option value="">Select Activity</option>
                  {activity.map(option => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <div className="text-danger">{activityError}</div>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="phone" className="mb-1">Telephone</label>
                <input
                  type="number"
                  className="form-control"
                  value={phone}
                  readOnly='true'
                  onChange={(e) => setPhone(e.target.value)}
                />
                <div className="text-danger">{phoneError}</div>
              </div>

              <div className="text-center mt-4 mb-4">
                <button type="button" onClick={handleEdit} className="btn btn-success me-4">Submit</button>
                <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
              </div>
            </Modal.Body>
          </Modal>

        </>
      )}
    </div>
  );
}
