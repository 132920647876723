import React from "react";
import { useEffect, useRef, useState } from "react"
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import SidebarPage from "./../../component/sidebar";
import ProgressBar from "react-bootstrap/ProgressBar";
import LineCharts from "./../../component/lineChart";
import LineChart1 from "./../../component/lineChart1";
import BarChart from "./../../component/barChart";
import BarChart1 from "./../../component/barChart1";
import logo from "./../../img/logo.png";
import MyHashtagPagination from "../configration/MyHashtagPagination";

export default function DashboardPage(props) {
  const {fetchList, users, allHashtag, hashtags} = props;
  //hashtagpagination
  const [currentHashtagPage, setCurrentHashtagPage] = useState(1);
  const [postPerPageHashtag, setpostperPageHashtag] = useState(5);

  const indexOfLastPostHashtag = currentHashtagPage * postPerPageHashtag;
  const indexOfFirstPostHashtag = indexOfLastPostHashtag - postPerPageHashtag;
  const hashtagPaginate = React.useCallback((currentHashtagPage) => {
    setCurrentHashtagPage(currentHashtagPage)
  }, [])
  const handleChangeRowsPerPage = (event) => {
    setpostperPageHashtag(parseInt(event.target.value, 2));
    setCurrentHashtagPage(1);
  };
  useEffect(() => {
    fetchList({});
  }, []);

  useEffect(() => {
    allHashtag({});
  }, []);

  const [open, setOpen] = useState(false);
  const appHeader = () => {
    setOpen(true);
  };
  
  const now = 82;
  const now1 = 34;
  const now2 = 70;
  const now3 = 15;
  const now4 = 68;
  
  return (
    <>
      <div className="mobile_header" >
        <div className={open ? "mobile_righticon" : "iconText" }>
          <i class="fa fa-list" onClick={appHeader} >
            {open ? <SidebarPage open={open} /> : <img src={logo} className="mobile_logo" alt="" />}
          </i>
        </div>
      </div>
      <div className="rightSection" style={{background: "#F8F9FD"}}>
        <Container fluid spacing={0}>
          <Row className="rowTop">
            <Col sm={12} md={12} lg={4}>
              <div className="numberCard">
                <p className="numberCardTitle">
                  Number of paying subscribers by service
                </p>
                <div className="progressDivWrapper">
                  <div className="progressDiv">
                    <p className="numberP1">Nutrition</p>
                    <ProgressBar
                      className="progressBar"
                      now={now}
                      label={`${now}%`}
                      visuallyHidden
                    />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <p className="numberP2">892</p>
                  </div>
                  <div className="progressDiv pdOne">
                    <p className="numberP1">Yoga</p>
                    <ProgressBar
                      className="progressBar pbOne"
                      now={now1}
                      label={`${now1}%`}
                      visuallyHidden
                    />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <p className="numberP2">346</p>
                  </div>
                  <div className="progressDiv pdOne">
                    <p className="numberP1">Box</p>
                    <ProgressBar
                      className="progressBar pbTwo"
                      now={now2}
                      label={`${now2}%`}
                      visuallyHidden
                    />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <p className="numberP2">708</p>
                  </div>
                  <div className="progressDiv pdOne">
                    <p className="numberP1">Sport&nbsp;1</p>
                    <ProgressBar
                      className="progressBar pbThree"
                      now={now3}
                      label={`${now3}%`}
                      visuallyHidden
                    />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <p className="numberP2">150</p>
                  </div>
                  <div className="progressDiv pdOne">
                    <p className="numberP1">Sport&nbsp;2</p>
                    <ProgressBar
                      className="progressBar pbFour"
                      now={now4}
                      label={`${now4}%`}
                      visuallyHidden
                    />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <p className="numberP2">680</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div className="numberCardRow">
                <p className="numberCardTitle">Favourite hashtags</p>
                <div
                  style={{
                    textAlign: "right",
                    marginTop: "-37px",
                    padding: "0 11px",
                  }}
                >
                  <p
                    style={{
                      color: "#000",
                      fontSize: "10px",
                      fontWeight: "400",
                      lineHeight: "15px",
                    }}
                  >
                    <i>Times used</i>
                  </p>
                </div>
                {hashtags && Array.isArray(hashtags) && hashtags.slice(indexOfFirstPostHashtag, indexOfLastPostHashtag)
                  .map((item) => (
                    <div className="hashtagsList">
                      <p className="hashtagP">{item.name}&nbsp;</p>
                      <p className="numberRight hashtagP">60</p>
                    </div>
                ))}
                <MyHashtagPagination
                      size="sm"
                      postPerPageHashtag={postPerPageHashtag}
                      currentHashtagPage={currentHashtagPage}
                      hashtagPaginate={hashtagPaginate}
                      totalPosts={hashtags.length}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
              </div>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div className="userBase">
                <p>
                  Total number of users in
                  <br /> the base :
                </p>
                <span>{users.length}</span>
              </div>
              <div
                className="numberOfSupport"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <div className="userBase1">
                  <p>
                    Number of
                    <br /> suport :
                  </p>
                  <span>56</span>
                </div>
                <div className="userBase1">
                  <p>
                    Number of
                    <br /> reports :
                  </p>
                  <span>65</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="chartRow">
            <Col sm={12} md={12} lg={6}>
              <LineCharts />
            </Col>
            <Col sm={12} md={12} lg={6}>
              <LineChart1 />
            </Col>
            <Col sm={12} md={12} lg={6}>
              <BarChart />
            </Col>
            <Col sm={12} md={12} lg={6}>
              <BarChart1 />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
