import * as actionTypes from "./giftActionType";

const initialState = {
    isLoading: false,
    isError: false,
    isGift: false,
    isLoggedIn: localStorage.getItem("admin_access_token") !== null ? true : false,
    loggedUser: localStorage.getItem("admin_details") !== null ? JSON.parse(localStorage.getItem("admin_details")) : {},
    gift: [],
    giftList: {},
    count: 0
    
}

export const giftReducer = (state = initialState, action) => {
    switch (action.type) {   
        case actionTypes.ALL_GIFT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isGift: false,
            };
        case actionTypes.ALL_GIFT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isGift: false,
            };
        case actionTypes.ALL_GIFT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isGift: true,
                gift: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : [],
            };
        case actionTypes.CREATE_GIFT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isGift: false
            };
        case actionTypes.CREATE_GIFT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isGift: false
            };
        case actionTypes.CREATE_GIFT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isGift: true
            };  
        case actionTypes.EDIT_GIFT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isGift: false
            };
        case actionTypes.EDIT_GIFT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isGift: false
            };
        case actionTypes.EDIT_GIFT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isGift: true,
                giftList: action.payload.data,
            }; 
        case actionTypes.UPDATE_GIFT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isGift: false
            };
        case actionTypes.UPDATE_GIFT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isGift: false
            };
        case actionTypes.UPDATE_GIFT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isGift: true,
            }; 
        case actionTypes.DELETE_GIFT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isGift: false
            };
        case actionTypes.DELETE_GIFT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isGift: false
            };
        case actionTypes.DELETE_GIFT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isGift: true
            };    
        default: 
            return state
    }
}