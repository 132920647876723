import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
// import Modal from "react-bootstrap/Modal";
//img
import logo from "./../../img/logo.png";
// component
import SidebarPage from "./../../component/sidebar";
import { Link } from "react-router-dom";

export default function ReportsPage() {
  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const [open, setOpen] = React.useState(false);

  const appHeader = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="mobile_header" >
        <div className={open ? "mobile_righticon" : "iconText" }>
          <i class="fa fa-list" onClick={appHeader} >
            {open ? <SidebarPage open={open} /> : <img src={logo} className="mobile_logo" alt="" />}
          </i>
        </div>
      </div>
      <div className="rightSection">
        <div className="divWrapper1">
          <div style={{ display: "flex", width: "500px" }}>
            <Link to="/reports" className="actUnder">
              <div className="actBox">
                <p>Ongoing</p>
              </div>
            </Link>
            <Link to="/report-resolve" className="actUnder">
              <div className="resBox">
                <p>Resolved</p>
              </div>
            </Link>
          </div>
          <Table className="tableBody12">
            <thead>
              <tr className="tableTR">
                <th className="tableHeadTxt1 txtWidth">Type</th>
                <th className="tableHeadTxt1 txtWidth1">Titre</th>
                <th className="tableHeadTxt1 txtWidth2">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr className="tableTR">
                <td className="tableTxt1">shared meal</td>
                <td className="tableTxt1">Lorem ipsum dolor si amet</td>
                <td className="tableTxt1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu ad litora torquent.
                </td>
              </tr>
              <tr className="tableTR">
                <td className="tableTxt1">shared meal</td>
                <td className="tableTxt1">Lorem ipsum dolor si amet</td>
                <td className="tableTxt1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu ad litora torquent.
                </td>
              </tr>
              <tr className="tableTR rrsov">
                <td className="tableTxt1">shared meal</td>
                <td className="tableTxt1">Lorem ipsum dolor si amet</td>
                <td className="tableTxt1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                  Class aptent taciti sociosqu ad litora torquent.
                </td>
              </tr>
              <tr className="tableTR">
                <td></td>
                <td className="tableTR"></td>
                <td className="tableTxt1"></td>
                <td className="tableTxt1"></td>
                <td style={{ display: "flex" }}>
                  <i class="fa-solid fa-angle-left rtlBtn"></i>
                  <div>
                    <Pagination size="sm">{items}</Pagination>
                  </div>
                  <i class="fa-solid fa-angle-right rtlBtn"></i>
                </td>
              </tr>
            </tbody>
            {/* <div>
              <div>
                <p className="tableTxt">
                  <i>Showing 1-8 of 160 users</i>
                </p>
              </div>
              <div>
              <i class="fa-solid fa-angle-left"></i>
                  <span>
                    <ul style={{ listStyleType: "none" }}>
                        <li>1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                        <li>5</li>
                    </ul>
                  </span>
                  <i class="fa-solid fa-angle-right"></i>
              </div>
            </div> */}
          </Table>
        </div>
      </div>
    </>
  );
}
