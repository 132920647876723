// import { Container, Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import logo from "./../../img/logo.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import emailIcon from './../../img/@-img.png'
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function LoginPage(props) {
  const { isLoggedIn, userLogin } = props;
  const navigate = useNavigate();
  const [login, setLogin] = useState({
    username: "",
    password: "",
  });

  if (isLoggedIn) {
    setTimeout(() => { navigate("/dashboard") }, 2000)
  }
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="loginWrapper">
      <div className="loginSideDiv"></div>
      <div className="loginMainDiv">
        <div className="logoDiv">
          <img src={logo} alt="" className="logo" />
          <p className="title">WOFICH</p>
        </div>
        <form
          noValidate
          onSubmit={(event) => {
            event.preventDefault();
            userLogin(login);
          }}
        >
          <div style={{ marginTop: "83px" }}>
            <div className="loginInputWrapper">
              <p className="loginInputWrapperTitle">LOGIN</p>
              <div className="inputWrapper">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa-solid fa-user loginInputIcon"></i>
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="User Name"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    id="username"
                    value={login.username}
                    onChange={(event) =>
                      setLogin({
                        ...login,
                        username: event.target.value,
                      })
                    }
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa-solid fa-lock-open loginInputIcon"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="password"
                    placeholder="password"
                    aria-label="password"
                    aria-describedby="basic-addon1"
                    id="password"
                    value={login.password}
                    onChange={(event) =>
                      setLogin({
                        ...login,
                        password: event.target.value,
                      })
                    }
                  />
                </InputGroup>
              </div>
              <div className="chechboxWrapper">
                <Form.Check type="checkbox" area-lable="Remember me" />
                <p className="rememberP">Remember me</p>
              </div>
              <div className="loginButtonWrapper">
                <Button className="loginButton" type="submit">
                  LOGIN
                </Button>
              </div>
              <div className="recoverPasswordWrapper">
                <p>Forgot your password?</p>
                <p onClick={handleShow} className="resetPassLink">
                  Click here
                </p>
                <Modal className="loginModBox" show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body closeButton>
                    <div style={{ textAlign: "center" }}>
                      <p className="modelBoxText">
                        Enter your email below and
                        <br /> we will send you a link to
                        <br /> change your password:
                      </p>
                    </div>
                    <div style={{ marginTop: "40px" }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <img src={emailIcon} alt="emailIcom" />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="email"
                          aria-label="Email"
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "54px",
                      }}
                    >
                      <Button className="sendButton">Send</Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="loginSideDiv"></div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
