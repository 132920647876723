export const CREATE_GIFT = "CREATE_GIFT";
export const CREATE_GIFT_SUCCESS = "CREATE_GIFT_SUCCESS";
export const CREATE_GIFT_FAILURE = "CREATE_GIFT_FAILURE";

export const ALL_GIFT = "ALL_GIFT";
export const ALL_GIFT_SUCCESS = "ALL_GIFT_SUCCESS";
export const ALL_GIFT_FAILURE = "ALL_GIFT_FAILURE";

export const EDIT_GIFT = "EDIT_GIFT";
export const EDIT_GIFT_SUCCESS = "EDIT_GIFT_SUCCESS";
export const EDIT_GIFT_FAILURE = "EDIT_GIFT_FAILURE";

export const UPDATE_GIFT = "UPDATE_GIFT";
export const UPDATE_GIFT_SUCCESS = "UPDATE_GIFT_SUCCESS";
export const UPDATE_GIFT_FAILURE = "UPDATE_GIFT_FAILURE";

export const DELETE_GIFT = "DELETE_GIFT";
export const DELETE_GIFT_SUCCESS = "DELETE_GIFT_SUCCESS";
export const DELETE_GIFT_FAILURE = "DELETE_GIFT_FAILURE";