
import * as actionTypes from "./subscriptionActionType";
import axios from "axios";
import { baseUrl } from "../../config";
import { toast } from 'react-toastify';

export const getSubscription = (id) => {

  return (dispatch) => {
    dispatch(getSubscriptionInit());
    axios.get(`${baseUrl}/subscription/get_subscribers/${id}`)
      .then((response) => {

        dispatch(getSubscriptionSuccess(response.data.data));
      })
      .catch(function (error) {
        dispatch(getSubscriptionFailure());
      })
  };
};

export const getSubscriptionInit = () => ({
  type: actionTypes.ALL_SUBSCRIPTION
});

export const getSubscriptionFailure = data => ({
  type: actionTypes.ALL_SUBSCRIPTION_FAILURE
});

export const getSubscriptionSuccess = data => ({
  type: actionTypes.ALL_SUBSCRIPTION_SUCCESS,
  payload: { data }
});


export const getPaidSubscription = (id) => {

  return (dispatch) => {
    dispatch(getPaidSubscriptionInit());
    axios.get(`${baseUrl}/paid_options/${id}`)
      .then((response) => {

        dispatch(getPaidSubscriptionSuccess(response.data.data));
      })
      .catch(function (error) {
        dispatch(getPaidSubscriptionFailure());
      })
  };
};

export const getPaidSubscriptionInit = () => ({
  type: actionTypes.ALL_PAID_SUBSCRIPTION
});

export const getPaidSubscriptionFailure = data => ({
  type: actionTypes.ALL_PAID_SUBSCRIPTION_FAILURE
});

export const getPaidSubscriptionSuccess = data => ({
  type: actionTypes.ALL_PAID_SUBSCRIPTION_SUCCESS,
  payload: { data }
});



export const getCancelSubscription = (id) => {
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem('user_access_token')}` } };
  return (dispatch) => {
    dispatch(getCancelSubscriptionInit());
    axios.delete(`${baseUrl}/subscription/${id}`,config)
      .then((response) => {
        toast.success("Cancel Subscription", {
          position: toast.POSITION.TOP_RIGHT
        });
        dispatch(getCancelSubscriptionSuccess(response.data.data));
      })
      .catch(function (error) {
        toast.error(error.response.status, {
          position: toast.POSITION.TOP_RIGHT
        });
        dispatch(getCancelSubscriptionFailure());
      })
  };
};

export const getCancelSubscriptionInit = () => ({
  type: actionTypes.CANCEL_SUBSCRIPTION
});

export const getCancelSubscriptionFailure = data => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_FAILURE
});

export const getCancelSubscriptionSuccess = data => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
  payload: { data }
});



export const getPaymentMethod = (id) => {
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem('admin_access_token')}` } };
  return (dispatch) => {
    dispatch(getPaymentMethodInit());
    axios.get(`${baseUrl}/card_details/admin/${id}`)
      .then((response) => {

        dispatch(getPaymentMethodSuccess(response.data.data));
      })
      .catch(function (error) {
        toast.error(error.response.status, {
          position: toast.POSITION.TOP_RIGHT
        });
        dispatch(getPaymentMethodFailure());
      })
  };
};

export const getPaymentMethodInit = () => ({
  type: actionTypes.PYMENT_METHOD_DETILAS
});

export const getPaymentMethodFailure = data => ({
  type: actionTypes.PYMENT_METHOD_FAILURE
});

export const getPaymentMethodSuccess = data => ({
  type: actionTypes.PYMENT_METHOD_SUCCESS,
  payload: { data }
});