export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAILURE = "CREATE_ACTIVITY_FAILURE";

export const ALL_ACTIVITY = "ALL_ACTIVITY";
export const ALL_ACTIVITY_SUCCESS = "ALL_ACTIVITY_SUCCESS";
export const ALL_ACTIVITY_FAILURE = "ALL_ACTIVITY_FAILURE";

export const EDIT_ACTIVITY = "EDIT_ACTIVITY";
export const EDIT_ACTIVITY_SUCCESS = "EDIT_ACTIVITY_SUCCESS";
export const EDIT_ACTIVITY_FAILURE = "EDIT_ACTIVITY_FAILURE";

export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAILURE = "UPDATE_ACTIVITY_FAILURE";

export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILURE = "DELETE_ACTIVITY_FAILURE";