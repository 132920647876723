import * as actionTypes from "./userActionType";

const initialState = {
    isLoading: false,
    isError: false,
    isSaving: false,
    isDelete: false,
    isLoggedIn: localStorage.getItem("admin_access_token") !== null ? true : false,
    loggedUser: localStorage.getItem("admin_details") !== null ? JSON.parse(localStorage.getItem("admin_details")) : {},
    users: [],
    userofficialreq: [],
    detail: [],
    official: false,
    count: 0,
    fitness: [],
    nutration_data: [],
    share_workout_data:[],
    activity:[],
    share_food_data:[],
    objectives:[]

}

export const userReducer = (state = initialState, action) => {
    console.log("action", actionTypes.FETCH_FITNESS_DETAIL)
    switch (action.type) {
        case actionTypes.USER_LOGIN:
            return {
                ...state,
                isLoading: true,
                isError: false,
                isLoggedIn: false,
                loggedUser: {}
            };
        case actionTypes.USER_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                isLoggedIn: false,
                loggedUser: {}
            };
        case actionTypes.USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isLoggedIn: true,
                loggedUser: action.payload ? action.payload.data : {},
            };
        case actionTypes.FETCH_LIST:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case actionTypes.FETCH_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case actionTypes.FETCH_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                users: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : [],
            };
        case actionTypes.FETCH_USER_DETAIL:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case actionTypes.FETCH_USER_DETAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case actionTypes.FETCH_USER_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                detail: action.payload ? action.payload.data : {}
            };

        case actionTypes.USER_UPDATE:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case actionTypes.USER_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case actionTypes.USER_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                detail: action.payload ? action.payload.data : {}
            };

        case actionTypes.USER_DELETE:
            return {
                ...state,
                isLoading: true,
                isError: false,
                isDelete: false
            };
        case actionTypes.USER_DELETE_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                isDelete: false
            };
        case actionTypes.USER_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isDelete: true
            };

        case actionTypes.FETCH_OFFICIAL:
            return {
                ...state,
                isLoading: true,
                isError: false,
                official: false
            };
        case actionTypes.FETCH_OFFICIAL_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                official: false
            };
        case actionTypes.FETCH_OFFICIAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                official: true
            };
        case actionTypes.FETCH_FITNESS_DETAIL:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case actionTypes.FETCH_FITNESS_DETAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case actionTypes.FETCH_FITNESS_DETAIL_SUCCESS:
            console.log("action.payload.data", action.payload.data)
            return {
                ...state,
                isLoading: false,
                isError: false,
                fitness: action.payload ? action.payload.data : [],
            };
        case actionTypes.FETCH_NUTRATION_DETAIL:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case actionTypes.FETCH_NUTRATION_DETAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case actionTypes.FETCH_NUTRATION_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                nutration_data: action.payload ? action.payload.data.f_response : [],
            };
        case actionTypes.FETCH_SHARE_FOOD_DETAIL:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case actionTypes.FETCH_SHARE_FOOD_DETAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case actionTypes.FETCH_SHARE_FOOD_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                share_food_data: action.payload ? action.payload.data.rows : [],
            };

        case actionTypes.FETCH_SHARE_WORKOUT_DETAIL:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case actionTypes.FETCH_SHARE_WORKOUT_DETAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case actionTypes.FETCH_SHARE_WORKOUT_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                share_workout_data: action.payload ? action.payload.data.rows : [],
            };
            case actionTypes.ALL_ACTIVITY:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                    isSaving: false,
                };
            case actionTypes.ALL_ACTIVITY_FAILURE:
                return {
                    ...state,
                    isError: true,
                    isLoading: false,
                    isSaving: false,
                };
            case actionTypes.ALL_ACTIVITY_SUCCESS:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    isSaving: false,
                    activity: action.payload ? action.payload.data.rows : [],
                    count: action.payload ? action.payload.data.count : [],
                };
                case actionTypes.ALL_OBJECTIVE:
                    return {
                        ...state,
                        isError: false,
                        isLoading: true,
                        isObjective: false,
                    };
                case actionTypes.ALL_OBJECTIVE_FAILURE:
                    return {
                        ...state,
                        isError: true,
                        isLoading: false,
                        isObjective: false,
                    };
                case actionTypes.ALL_OBJECTIVE_SUCCESS:
                    return {
                        ...state,
                        isError: false,
                        isLoading: false,
                        isObjective: true,
                        objectives: action.payload ? action.payload.data.rows : [],
                        count: action.payload ? action.payload.data.count : [],
                    };
                    case actionTypes.ALL_STATUS_REQUEST:
                        return {
                            ...state,
                            isLoading: true,
                            isError: false,
                        };
                    case actionTypes.ALL_STATUS_REQUEST_FAILURE:
                        return {
                            ...state,
                            isLoading: false,
                            isError: true,
                        };
                    case actionTypes.ALL_STATUS_REQUEST_SUCCESS:
                        return {
                            ...state,
                            isLoading: false,
                            isError: false,
                            users: action.payload ? action.payload.data.rows : [],
                            count: action.payload ? action.payload.data.count : [],
                        };
        default:
            return state
    }
}

