import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const MyObjectivePagination = ({ totalPosts, objectivePaginate, currentObjPage, postPerPageObj }) => {
    const pageNumbers = [];
    if(currentObjPage > 1) {
        pageNumbers.push(<Pagination.Prev key="prev" onClick={() => objectivePaginate(currentObjPage - 1)} />)
    }

    for(let page = 1; page <= Math.ceil(totalPosts / postPerPageObj); page++) {
        pageNumbers.push(
            <Pagination.Item key={page} data-page={page} active={page === currentObjPage} onClick={() => objectivePaginate(page)}>
                {page}
            </Pagination.Item>
        )
    }

    if (currentObjPage <= 1) {
        pageNumbers.push(<Pagination.Next key="next" onClick={() => objectivePaginate(currentObjPage + 1)} />)
    }

    return (
        <>
            <Pagination size="sm" style={{float: "right"}}>{pageNumbers}</Pagination>
        </>
        
    )
}

export default MyObjectivePagination;