export const ALL_SUBSCRIPTION = "ALL_SUBSCRIPTION";
export const ALL_SUBSCRIPTION_SUCCESS = "ALL_SUBSCRIPTION_SUCCESS";
export const ALL_SUBSCRIPTION_FAILURE = "ALL_SUBSCRIPTION_FAILURE";


export const ALL_PAID_SUBSCRIPTION = "ALL_PAID_SUBSCRIPTION";
export const ALL_PAID_SUBSCRIPTION_SUCCESS = "ALL_PAID_SUBSCRIPTION_SUCCESS";
export const ALL_PAID_SUBSCRIPTION_FAILURE = "ALL_PAID_SUBSCRIPTION_FAILURE";


export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";


export const PYMENT_METHOD_DETILAS = "PYMENT_METHOD_DETILAS";
export const PYMENT_METHOD_SUCCESS = "PYMENT_METHOD_SUCCESS";
export const PYMENT_METHOD_FAILURE = "PYMENT_METHOD_FAILURE";

