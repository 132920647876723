import * as actionTypes from "./objectiveActionType";
import axios from "axios";
import { baseUrl } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const getObjective = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(getObjectiveInit());
        axios.get(`${baseUrl}/objective`, data, {})
            .then((response) => {
                if (response.data.message === "Success") {
                    dispatch(getObjectiveSuccess(response.data.data));
                } else {
                    dispatch(getObjectiveFailure());
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch(getObjectiveFailure());
            });
    };
};

export const createObjective = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(createObjectiveInit());
        axios.post(`${baseUrl}/objective/store`, data, {})
            .then((response) => {
                if (response.data.message === "Success") {
                    toast.success("Create Successfully", {});
                    dispatch(createObjectiveSuccess(response.data.data));
                } else {
                    dispatch(createObjectiveFailure());
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch(createObjectiveFailure());
            });
    };
};
export const editObjective = (id) => {
    return (dispatch) => {
        dispatch(editObjectiveInit());
        axios.get(`${baseUrl}/objective/${id}`, {})
            .then((response) => {
                if (response.data.message === "Success") {
                    dispatch(editObjectiveSuccess(response.data.data));
                } else {
                    dispatch(editObjectiveFailure());
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch(editObjectiveFailure());
            });
    };
};

export const updateObjective = (data) => {
    return (dispatch) => {
        dispatch(updateObjectiveInit());
        axios.put(`${baseUrl}/objective/${data.id}`, data)
            .then((response) => {
                if (response.data.message === "Success") {
                    toast.success("Update Successfully", {});
                    dispatch(updateObjectiveSuccess(response.data.data));
                    console.warn(response.data.data);
                } else {
                    dispatch(updateObjectiveFailure());
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch(updateObjectiveFailure());
            });
    };
};

export const deleteObjective = (id) => {
    console.log("id :---", id);
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
  
        dispatch(deleteObjectiveInit());
        axios.delete(`${baseUrl}/objective/${id}`, {})
            .then((response) => {
             
                if (response.data.message === "Success") {
                    toast.success("Delete Successfully", {});

                    dispatch(deleteObjectiveSuccess(response.data.data));


                } else {
                    dispatch(deleteObjectiveFailure());
                }
            })
            .catch(function (error) {
           
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch(deleteObjectiveFailure());
            });
    };
};

export const createObjectiveInit = () => ({
    type: actionTypes.CREATE_OBJECTIVE
});

export const createObjectiveFailure = data => ({
    type: actionTypes.CREATE_OBJECTIVE_FAILURE
});

export const createObjectiveSuccess = data => ({
    type: actionTypes.CREATE_OBJECTIVE_SUCCESS,
    payload: { data }
});

export const getObjectiveInit = () => ({
    type: actionTypes.ALL_OBJECTIVE
});

export const getObjectiveFailure = data => ({
    type: actionTypes.ALL_OBJECTIVE_FAILURE
});

export const getObjectiveSuccess = data => ({
    type: actionTypes.ALL_OBJECTIVE_SUCCESS,
    payload: { data }
});

export const editObjectiveInit = () => ({
    type: actionTypes.EDIT_OBJECTIVE
});

export const editObjectiveFailure = data => ({
    type: actionTypes.EDIT_OBJECTIVE_FAILURE
});

export const editObjectiveSuccess = data => ({
    type: actionTypes.EDIT_OBJECTIVE_SUCCESS,
    payload: { data }
});

export const updateObjectiveInit = () => ({
    type: actionTypes.UPDATE_OBJECTIVE
});

export const updateObjectiveFailure = data => ({
    type: actionTypes.UPDATE_OBJECTIVE_FAILURE
});

export const updateObjectiveSuccess = data => ({
    type: actionTypes.UPDATE_OBJECTIVE_SUCCESS,
    payload: { data }
});

export const deleteObjectiveInit = () => ({
    type: actionTypes.DELETE_OBJECTIVE
});

export const deleteObjectiveFailure = data => ({
    type: actionTypes.DELETE_OBJECTIVE_FAILURE
});

export const deleteObjectiveSuccess = data => ({
    type: actionTypes.DELETE_OBJECTIVE_SUCCESS,
    payload: { data }
});




