import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
//img
import logo from "./../../img/logo.png";
// component
import SidebarPage from "./../../component/sidebar";
import MyPagination from "./MyPagination";
import { confirmAlert } from 'react-confirm-alert';
import Image from "react-bootstrap/Image";
import 'react-confirm-alert/src/react-confirm-alert.css';
import MyObjectivePagination from "./MyObjectivePagination";
import MyHashtagPagination from "./MyHashtagPagination";
import MyGiftPagination from "./MyGiftPagination";
import MyWalletPagination from "./MyWalletPagination";
import Carousel from 'react-bootstrap/Carousel';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from 'react-loader-spinner';
export default function ConfigrationPage(props) {
  const { getActivity, createActivity, editActivity, updateActivity, deleteActivity, createHashtag, activity, activityList,
    allHashtag, hashtags, deleteHashtag, editHashtag, list, updateHashtag, getObjective, createObjective, editObjective,
    updateObjective, deleteObjective, objectives, objectiveList, isSaving, isObjective, isHashtag } = props;

  const { gift, getGift, deleteGift, giftList, editGift, updateGift, createGift, isGift } = props;
  const { wallet, getWallet, deleteWallet, walletList, editWallet, updateWallet, createWallet, isWallet } = props;
  const [selected, setSelected] = useState({
    name: "",
    description: ""
  });
  const [hashtag, setHashtag] = useState({
    name: "",
  })
  const [objective, setObjective] = useState({
    name: "",
  })

  const [gifts, setGifts] = useState({
    title: "",
    icon: "",
    amount: ""
  })

  const [wallets, setWallets] = useState({
    title: "",
    amount: ""
  })
  const [showActive, setShowActive] = useState(false);
  const [showObjacive, setShowObjacive] = useState(false);
  const [showHashtag, setShowHashtag] = useState(false);
  const [showGift, setShowGift] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostperPage] = useState(4);

  useEffect(() => {
    getGift();
    if (isGift) {
      getGift();
    }


    if (isHashtag) {
      allHashtag();
    }
    if (isObjective) {
      getObjective();
    }
    allHashtag();
    getObjective();
    getActivity();
    getWallet();
  }, []);

  useEffect(() => {
    if (walletList) {
      setWallets({ ...walletList });
    }
  }, [walletList]);

  useEffect(() => {
    if (activityList) {
      setSelected({ ...activityList });
    }
  }, [activityList]);

  useEffect(() => {
    if (objectiveList) {
      setObjective({ ...objectiveList });
    }
  }, [objectiveList]);

  useEffect(() => {
    if (list) {
      setHashtag({ ...list });
    }
  }, [list]);

  useEffect(() => {
    if (giftList) {
      setGifts({ ...giftList });
    }
  }, [giftList]);

  const confirmGiftDelete = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
  
      buttons: [
        {
          label: 'Yes',
          backgroundColor:'red',
          onClick: () => {
            deleteGift(id);
            setCurrentGiftPage(1)
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  const confirmWalletDelete = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteWallet(id);
            setCurrentWalletPage(1)
          }
        },
        {
          label: 'No'
        }
      ]
    });

    setTimeout(() => {
      getWallet();
    }, 3000);
  }
  const confirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteHashtag(id);
            setCurrentHashtagPage(1)
          },
        },
        {
          label: 'No'
        }
      ]
    });
    setTimeout(() => {
      allHashtag();
    }, 3000);
  }



  const confirmDeleteActivity = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          
          onClick: () => {
            deleteActivity(id);
            setCurrentPage(1)
          }
        },
        {
          label: 'No'
        }
      ]
    });
    setTimeout(() => {
      getActivity();
    }, 3000);
  }



  const [isLoading, setIsLoading] = useState();

  const confirmDeleteObjective = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deteleObjextive(id);
          }
        },
        {
          label: 'No'
        }
      ]
    });


  }

  //

  const deteleObjextive = async (id) => {
    try {
      // Set loading state to true
      setIsLoading(true);

      // Call the deleteObjective action
      await deleteObjective(id);

      // After successful deletion, you can perform additional actions
      await getObjective(); // Refresh the objectives after deletion
      await setCurrentObjPage(1);
       
    } catch (error) {
      setIsLoading(false);
      // Handle errors if necessary
    } finally {
      // Clear loading state (whether success or failure)
      setIsLoading(false);
    }
  };

  // const deteleObjextive = (id) => {

  //   deleteObjective(id)
  //     // Assuming deleteObjective is an async function that returns a promise
  //     getObjective(); // Refresh the objectives after deletion
  //     setCurrentObjPage(1);

  // }

  const handleClose = () => {
    setShowActive(false);
    setSelected("");
  }
  const handleShow = () => setShowActive(true);

  const objaciveClose = () => {
    setShowObjacive(false);
    setObjective("");
  }
  const objaciveShow = () => setShowObjacive(true);

  const hashtagClose = () => {
    setShowHashtag(false);
    setHashtag("");
  }
  const hashtagShow = () => setShowHashtag(true);

  const giftShow = () => setShowGift(true);
  const giftClose = () => {
    setShowGift(false);
    setGifts("");
  }
  const walletShow = () => setShowWallet(true);
  const walletClose = () => {
    setShowWallet(false);
    setWallets("");
  }
  //



  const appHeader = () => {
    setOpen(true);
  };


  const submitActivity = (event) => {
    event.preventDefault();
    setShowActive(false);
    if (selected) {
      {
        selected.id
          ? updateActivity(selected)
          : createActivity(selected);
      }
    }
    setTimeout(() => {
      getActivity();
    }, 3000);
  }

  const submitObjective = (event) => {
    event.preventDefault();
    setShowObjacive(false);
    if (objective) {
      {
        objective.id
          ? updateObjective(objective)
          : createObjective(objective);
      }
    }
    setTimeout(() => {
      getObjective();
    }, 3000);
  }

  const submitHandle = (event) => {
    event.preventDefault();
    setShowHashtag(false);
    if (hashtag) {
      {
        hashtag.id
          ? updateHashtag(hashtag)
          : createHashtag(hashtag);
      }
    }
    setTimeout(() => {
      allHashtag();
    }, 3000);
  }

  const submitGiftHandle = (event) => {
    event.preventDefault();
    setShowGift(false);
    if (gifts) {
      {
        gifts.id
          ? updateGift(gifts)
          : createGift(gifts);
      }
    }
    setTimeout(() => {
      getGift();
    }, 3000);
  }

  const submitWalletHandle = (event) => {
    event.preventDefault();
    setShowWallet(false);
    if (wallets) {
      {
        wallets.id
          ? updateWallet(wallets)
          : createWallet(wallets);
      }
    }
    setTimeout(() => {
      getWallet();
    }, 3000);
  }

  //pagination


  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;

  const paginate = React.useCallback((currentPage) => {
    setCurrentPage(currentPage)
  }, [])

  const handleChangeRowsPerPage = (event) => {
    setpostperPage(parseInt(event.target.value, 4));
    setCurrentPage(1);
  };

  //objectivepagination
  const [currentObjPage, setCurrentObjPage] = useState(1);
  const [postPerPageObj, setpostperPageObj] = useState(4);

  const indexOfLastPostObj = currentObjPage * postPerPageObj;
  const indexOfFirstPostObj = indexOfLastPostObj - postPerPageObj;

  const objectivePaginate = React.useCallback((currentObjPage) => {
    setCurrentObjPage(currentObjPage)
  }, [])

  const handleChangeRowsPerObjPage = (event) => {
    setpostperPageObj(parseInt(event.target.value, 4));
    setCurrentObjPage(1);
  };

  //hashtagpagination
  const [currentHashtagPage, setCurrentHashtagPage] = useState(1);
  const [postPerPageHashtag, setpostperPageHashtag] = useState(4);

  const indexOfLastPostHashtag = currentHashtagPage * postPerPageHashtag;
  const indexOfFirstPostHashtag = indexOfLastPostHashtag - postPerPageHashtag;

  const hashtagPaginate = React.useCallback((currentHashtagPage) => {
    setCurrentHashtagPage(currentHashtagPage)
  }, [])

  const handleChangeRowsPerHashtagPage = (event) => {
    setpostperPageHashtag(parseInt(event.target.value, 4));
    setCurrentHashtagPage(1);
  };

  //giftpagination
  const [currentGiftPage, setCurrentGiftPage] = useState(1);
  const [postPerPagegift, setpostperPagegift] = useState(4);

  const indexOfLastPostGift = currentGiftPage * postPerPagegift;
  const indexOfFirstPostGift = indexOfLastPostGift - postPerPagegift;

  const giftPaginate = React.useCallback((currentGiftPage) => {
    setCurrentGiftPage(currentGiftPage)
  }, [])

  const handleChangeRowsPerGiftPage = (event) => {
    setpostperPagegift(parseInt(event.target.value, 4));
    setCurrentGiftPage(1);
  };

  //walletpagination
  const [currentWalletPage, setCurrentWalletPage] = useState(1);
  const [postPerPagewallet, setpostperPagewallet] = useState(4);

  const indexOfLastPostWallet = currentWalletPage * postPerPagewallet;
  const indexOfFirstPostWallet = indexOfLastPostWallet - postPerPagewallet;

  const walletPaginate = React.useCallback((currentWalletPage) => {
    setCurrentWalletPage(currentWalletPage)
  }, [])

  const handleChangeRowsPerWalletPage = (event) => {
    setpostperPagewallet(parseInt(event.target.value, 4));
    setCurrentWalletPage(1);
  };

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // const imgUrl=(gifts.id ? gifts.icon : "");
  // const imgFile=(gifts.id ? "" : gifts.icon);

  return (
    <div style={{
      width: "100%"
    }}>
      {isLoading ? (
        <div className="loader-container">
          <Oval
            height={100}
            width={100}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}

          />
        </div>
      ) : (
        <>
          <div className="mobile_header">
            <div className={open ? "mobile_righticon" : "iconText"}>
              <i class="fa fa-list" onClick={appHeader}>
                {open ? (
                  <SidebarPage open={open} />
                ) : (
                  <img src={logo} className="mobile_logo" alt="" />
                )}
              </i>
            </div>
          </div>
          <>
            <div className="rightSection">
              <div style={{ paddingTop: "39px" }}>
                <Row>
                  <Col sm={12} md={12} lg={7}>
                    <Row>
                      <Col sm={12} md={12} lg={7}>
                        <div className="minimumAmount">
                          <p className="minimumText">
                            Minimum amount to be able to
                            <br /> collect payment :
                          </p>
                          <div className="minimumData">
                            <p className="minimumDataP1">XX€</p>
                            <p className="minimumDataP2">Modify</p>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={12} lg={5}>
                        <div className="paymentMethod">
                          <p className="paymentMethodText">Payment method</p>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <Form.Check type="checkbox" area-lable="Remember me" />
                            <p className="paymentMethodP1">Paypal</p>
                          </div>
                          <div style={{ display: "flex" }}>
                            <Form.Check type="checkbox" area-lable="Remember me" />
                            <p className="paymentMethodP2">Stripe</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <div className="maximum" style={{ marginTop: "17px" }}>
                          <p className="minimumText">
                            Maximun participant allowed to participate in live
                            challenge :
                          </p>
                          <div className="maximumData">
                            <p className="minimumDataP1">XX</p>
                            <p className="minimumDataP2">Modify</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} md={12} lg={5}>
                    <div className="wofichCommision">
                      <p
                        className="wofichCommisionText"
                        style={{ marginBottom: "0px" }}
                      >
                        Wofich commission on subscription
                      </p>
                      <div className="wofichText">
                        <p className="wofichTextP1">5%</p>
                        <p className="wofichTextP2">Modify</p>
                      </div>
                      <p
                        className="wofichCommisionText"
                        style={{ marginBottom: "0px" }}
                      >
                        Wofich commission on shared
                      </p>
                      <div className="wofichText">
                        <p className="wofichTextP1">5%</p>
                        <p className="wofichTextP2">Modify</p>
                      </div>
                      <p
                        className="wofichCommisionText"
                        style={{ marginBottom: "0px", marginTop: "20px" }}
                      >
                        Wofich commission on shared
                      </p>
                      <div className="wofichText">
                        <p className="wofichTextP1">
                          <span className="wofichSpan">Organiser :</span>&nbsp;5%
                        </p>
                        <p className="wofichTextP2">Modify</p>
                      </div>
                      <div className="wofichText" style={{ marginBottom: "-11px" }}>
                        <p className="wofichTextP1">
                          <span className="wofichSpan">Winner/s :</span>&nbsp;5%
                        </p>
                        <p className="wofichTextP2">Modify</p>
                      </div>
                      <p
                        className="wofichCommisionText"
                        style={{ marginBottom: "0px" }}
                      >
                        Wofich commission on referral
                      </p>
                      <div className="wofichText">
                        <p className="wofichTextP1">5%</p>
                        <p className="wofichTextP2">Modify</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <p className="midText">
                Conversion rate : <i className="midItalic">1 euros</i>={" "}
                <i className="midItalic">10 wolf</i>
              </p>
              <div className="ActivitysDiv">
                <>
                  <Row>
                    <Col sm={12} md={12} lg={4}>
                      <p className="activityListTitle">Activity List </p>
                      <div className="divWrapperConfig">
                        <div className="activityList">
                          <div style={{ textAlign: "right" }}>
                            <span onClick={handleShow}>
                              <i class="fa-solid fa-plus plusIcon"></i>
                            </span>
                          </div>
                          {activity && Array.isArray(activity) && activity
                            .slice(indexOfFirstPost, indexOfLastPost)
                            .map((item) => (
                              <div style={{ display: "flex", marginTop: "15px", maxWidth: "370px" }}>
                                <p className="activitytarget" >
                                  {item.name}
                                </p>
                                <p className="activityBtns" style={{ marginLeft: "auto", marginRight: "25px" }}
                                  onClick={() => confirmDeleteActivity(item.id)}>Delete
                                </p>
                                <p className="activityBtns"
                                  onClick={() => {
                                    editActivity(item.id);
                                    handleShow();
                                  }}>Modify
                                </p>
                              </div>
                            ))}
                          {/* <div style={{ textAlign: "right" }}>
                        <i class="fa-solid fa-angle-down plusIcon"></i>
                      </div> */}
                        </div>
                        <MyPagination
                          size="sm"
                          postPerPage={postPerPage}
                          currentPage={currentPage}
                          paginate={paginate}
                          totalPosts={activity.length}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={12} lg={4}>
                      <p className="activityListTitle">Objective List </p>
                      <div className="divWrapperConfig">
                        <div className="objectiveList">
                          <div style={{ textAlign: "right" }}>
                            <span onClick={objaciveShow}>
                              <i class="fa-solid fa-plus plusIcon"></i>
                            </span>
                          </div>
                          {objectives && Array.isArray(objectives) && objectives
                            .slice(indexOfFirstPostObj, indexOfLastPostObj)
                            .map((item) => (
                              <div style={{ display: "flex", marginTop: "15px", maxWidth: "370px" }}>
                                <p className="objactiveTarget" >
                                  {item.name}
                                </p>
                                <p className="activityBtns" style={{ marginLeft: "auto", marginRight: "25px" }}
                                  onClick={() => confirmDeleteObjective(item.id)}>Delete
                                </p>
                                <p className="activityBtns"
                                  onClick={() => {
                                    editObjective(item.id);
                                    objaciveShow();
                                  }}>Modify
                                </p>
                              </div>
                            ))}
                          {/* <div style={{ textAlign: "right" }}>
                        <i class="fa-solid fa-angle-down plusIcon"></i>
                      </div> */}
                        </div>
                        <MyObjectivePagination
                          size="sm"
                          postPerPageObj={postPerPageObj}
                          currentObjPage={currentObjPage}
                          objectivePaginate={objectivePaginate}
                          totalPosts={objectives.length}
                          onChangeRowsPerObjPage={handleChangeRowsPerObjPage}
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={12} lg={4}>
                      <p className="activityListTitle">Hashtag List </p>
                      <div className="divWrapperConfig">
                        <div className="HashtagList">
                          <div style={{ textAlign: "right" }}>
                            <span onClick={hashtagShow}>
                              <i class="fa-solid fa-plus plusIcon"></i>
                            </span>
                          </div>
                          {hashtags && Array.isArray(hashtags) && hashtags
                            .slice(indexOfFirstPostHashtag, indexOfLastPostHashtag)
                            .map((item) => (
                              <div style={{ display: "flex", marginTop: "15px", maxWidth: "335", flexWrap: "wrap" }}>
                                <p className="hashtagTarget" >
                                  {item.name}
                                </p>
                                <p className="activityBtns" style={{ marginLeft: "auto", marginRight: "15px" }}
                                  onClick={() => confirmDelete(item.id)}>Delete
                                </p>
                                <p className="activityBtns"
                                  onClick={() => {
                                    editHashtag(item.id);
                                    hashtagShow();
                                  }}>Modify
                                </p>
                              </div>
                            ))}
                          {/* <div style={{ textAlign: "right" }}>
                        <i class="fa-solid fa-angle-down plusIcon"></i>
                      </div> */}
                        </div>
                        <MyHashtagPagination
                          size="sm"
                          postPerPageHashtag={postPerPageHashtag}
                          currentHashtagPage={currentHashtagPage}
                          hashtagPaginate={hashtagPaginate}
                          totalPosts={hashtags.length}
                          handleChangeRowsPerHashtagPage={handleChangeRowsPerHashtagPage}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
                <>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <Carousel interval={null}>
                        <Carousel.Item>
                          <p className="activityListTitle"
                            style={{ marginTop: "12px" }}>
                            Gift List
                          </p>
                          <div className="giftListDiv">
                            <div style={{ textAlign: "right" }}>
                              <span onClick={giftShow}>
                                <i class="fa-solid fa-plus plusIcon"></i>
                              </span>
                            </div>
                            <Table>
                              <thead>
                                <tr className="giftListTR">
                                  <th>Title</th>
                                  <th>Icon</th>
                                  <th>Amount</th>
                                  <th style={{ width: "11%" }}></th>
                                </tr>
                              </thead>
                              <tbody>
                                {gift && Array.isArray(gift) && gift.length > 0 ?
                                  gift.slice(indexOfFirstPostGift, indexOfLastPostGift).map((item) => (
                                    <tr className="giftListTR1 gtr">
                                      <td>{item.title}</td>
                                      <td>
                                        {item.icon ?
                                          <Image className="tableImg" alt="" src={item.icon} />
                                          : "N/A"
                                        }
                                      </td>
                                      <td>{item.amount}</td>
                                      <td className="textUnderLine"
                                        onClick={() => confirmGiftDelete(item.id)}>Delete
                                      </td>
                                      <td className="textUnderLine"
                                        onClick={() => {
                                          editGift(item.id);
                                          giftShow();
                                        }}>Modify
                                      </td>
                                    </tr>
                                  ))
                                  : <h6 style={{ marginTop: "10px;" }}>Record not found</h6>}
                              </tbody>
                            </Table>
                            {/* <div style={{ textAlign: "right" }}>
                      <i class="fa-solid fa-angle-down plusIcon"></i>
                    </div> */}
                            <MyGiftPagination
                              size="sm"
                              postPerPagegift={postPerPagegift}
                              currentGiftPage={currentGiftPage}
                              giftPaginate={giftPaginate}
                              totalPosts={gift.length}
                              handleChangeRowsPerGiftPage={handleChangeRowsPerGiftPage}
                            />
                          </div>
                        </Carousel.Item>
                        <Carousel.Item>
                          <p
                            className="activityListTitle"
                            style={{ marginTop: "12px" }}
                          >
                            Wallet Rechargable List
                          </p>
                          <div>
                            <div className="giftListDivWrapper">
                              <div style={{ textAlign: "right" }}>
                                <span onClick={walletShow}>
                                  <i class="fa-solid fa-plus plusIcon"></i>
                                </span>
                              </div>
                              <Table>
                                <thead>
                                  <tr className="giftListTR">
                                    <th>Title</th>
                                    <th>Amount</th>
                                    <th style={{ width: "11%" }}></th>
                                  </tr>
                                </thead>
                                <tbody>

                                  {wallet && Array.isArray(wallet) && wallet.length > 0 ?
                                    wallet.slice(indexOfFirstPostGift, indexOfLastPostGift).map((item) => (
                                      <tr className="giftListTR1 gtr">
                                        <td>{item.title}</td>
                                        <td>{item.amount}</td>
                                        <td className="textUnderLine"
                                          onClick={() => confirmWalletDelete(item.id)}>Delete
                                        </td>
                                        <td className="textUnderLine"
                                          onClick={() => {
                                            editWallet(item.id);
                                            walletShow();
                                          }}>Modify
                                        </td>
                                      </tr>
                                    ))
                                    : <h6 style={{ marginTop: "10px;" }}>Record not found</h6>}

                                </tbody>
                              </Table>
                              {/* <div style={{ textAlign: "right" }}>
                        <i class="fa-solid fa-angle-down plusIcon"></i>
                      </div> */}
                              <MyWalletPagination
                                size="sm"
                                postPerPagewallet={postPerPagewallet}
                                currentWalletPage={currentWalletPage}
                                walletPaginate={walletPaginate}
                                totalPosts={wallet.length}
                                handleChangeRowsPerWalletPage={handleChangeRowsPerWalletPage}
                              />
                            </div>
                          </div>
                        </Carousel.Item>
                      </Carousel>

                    </Col>
                    {/* <Col sm={12} md={12} lg={3}>
                
                </Col> */}
                  </Row>
                </>
              </div>
              <div className="">
                <Modal
                  className="activeDivModalBox"
                  show={showActive}
                  onHide={handleClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body closeButton>
                    <p className="modalBoxTitle">Add activity</p>
                    <Form className="d-flex">
                      <Form.Control
                        type="text"
                        placeholder="Type"
                        className="me-2 modalBoxSearchBar"
                        value={selected.name}
                        onChange={(event) =>
                          setSelected({
                            ...selected,
                            name: event.target.value,
                            name_french: event.target.value,
                          })
                        }
                      />
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button onClick={submitActivity} className="modalConfirm" type="button" form="form">
                        Confirm
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="">
                <Modal
                  className="activeDivModalBox"
                  show={showObjacive}
                  onHide={objaciveClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body closeButton>
                    <p className="modalBoxTitle">Add objective</p>
                    <Form className="d-flex">
                      <Form.Control
                        type="search"
                        placeholder="Type"
                        className="me-2 modalBoxSearchBar"
                        aria-label="Search"
                        value={objective.name}
                        onChange={(event) =>
                          setObjective({
                            ...objective,
                            name: event.target.value,
                          })
                        }
                      />
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button onClick={submitObjective} className="modalConfirm" type="submit">Confirm</Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="">
                <Modal
                  className="activeDivModalBox"
                  show={showHashtag}
                  onHide={hashtagClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body closeButton>
                    <p className="modalBoxTitle">
                      {hashtag.id ? "Update" : "Add"} hashtag
                    </p>
                    <Form className="d-flex">
                      <Form.Control
                        type="text"
                        placeholder="#type"
                        className="me-2 modalBoxSearchBar"
                        value={hashtag.name}
                        onChange={(event) =>
                          setHashtag({
                            ...hashtag,
                            name: event.target.value,
                          })
                        }
                      />
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button onClick={submitHandle}
                        type="submit" className="modalConfirm">Confirm</Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="">
                <Modal
                  className="activeDivModalBox"
                  show={showGift}
                  onHide={giftClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body closeButton>
                    {/* <p className="modalBoxTitle">Icon</p> */}
                    <div>
                      {gifts.icon ? <img src={gifts.icon} height="50px;" width="50px;"></img> : ''}
                      <label style={{ marginBottom: "10px" }}
                        htmlFor="header-image"
                        autoFocus
                        className="modalBoxTitle"
                      >
                        <input
                          accept="image/*"
                          id="header-image"
                          type="file"
                          onChange={event => {
                            if (event && event.target && event.target.files && event.target.files.length > 0) {
                              setGifts({
                                ...gifts,
                                icon: event.target.files ? event.target.files[0] : "",
                              });
                            }
                          }}
                        // style={{ display: "none" }}
                        />
                      </label>


                      {/* <div>
                    {gifts.icon != "" && !gifts.id && <img src={URL.createObjectURL(gifts.icon)} alt={imgFile.name} />}
                    {gifts.icon != "" && gifts.id && imgUrl && <img src={`${gifts.icon}`} alt='' />}
                  </div> */}
                    </div>
                    <Form className="d-flex">
                      <Container fluid spacing={0}>
                        <Row>
                          <Col item xs={12}>
                            <Form.Control
                              type="text"
                              placeholder="title"
                              className="me-2 modalBoxSearchBar"
                              value={gifts.title}
                              onChange={(event) =>
                                setGifts({
                                  ...gifts,
                                  title: event.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col item xs={12} style={{ marginTop: "10px" }}>
                            <Form.Control
                              type="text"
                              placeholder="amount"
                              className="me-2 modalBoxSearchBar"
                              value={gifts.amount}
                              onChange={(event) =>
                                setGifts({
                                  ...gifts,
                                  amount: event.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button className="modalConfirm" onClick={submitGiftHandle} type="submit">Confirm</Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="">
                <Modal
                  className="activeDivModalBox"
                  show={showWallet}
                  onHide={walletClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body closeButton>
                    {/* <p className="modalBoxTitle">Icon</p>
                <div>
                  <i class="fa-solid fa-camera addCameraIcon"></i>
                </div> */}
                    <Form className="d-flex">
                      <Container fluid spacing={0}>
                        <Row>
                          <Col item xs={12}>
                            <Form.Control
                              type="text"
                              placeholder="title"
                              className="me-2 modalBoxSearchBar"
                              value={wallets.title}
                              onChange={(event) =>
                                setWallets({
                                  ...wallets,
                                  title: event.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col item xs={12} style={{ marginTop: "10px" }}>
                            <Form.Control
                              type="text"
                              placeholder="amount"
                              className="me-2 modalBoxSearchBar"
                              value={wallets.amount}
                              onChange={(event) =>
                                setWallets({
                                  ...wallets,
                                  amount: event.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button className="modalConfirm" onClick={submitWalletHandle} type="submit">Confirm</Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

          </>
        </>
      )}
    </div>
  );
}
