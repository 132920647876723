import React, { useState }  from 'react';
import clsx from 'clsx';
import SidebarPage from '../../component/sidebar';
import { RoutePath } from './../../routes';
import { useLocation } from "react-router-dom";

export default function AppRoot(props) {
  const { isLoggedIn, loggedUser } = props;
  const [open, setOpen] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    const loc = location.pathname.split("/");
    // (loc[1] == '' ? setOpen(false) : (loc[1] == '' ? setOpen(false) : setOpen(true) )  )
    loc[1] == '' ? setOpen(false) : setOpen(true) ;
  }, [location]);

  return (
    <div className={!open  ? '' : "adminDashboard"} >
      {open ? (
        <SidebarPage loggedUser={loggedUser}  />
        ) : null}
          <RoutePath />
    </div>
  );
}