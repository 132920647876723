import React from "react";
import { ProtectedRoute } from "./shared/protected.route";
import { Routes, Route } from "react-router-dom";
import { LoginPageContainer } from "./views/login/login.container";
import { DashboardPageContainer } from "./views/dashboard/dashboard.container";
import { ListOfUsersPageContainer } from "./views/listOfUsers/listOfUsers.container";
import { ConfigrationPageContainer } from "./views/configration/configration.container";
import { CGUPageContainer } from "./views/legalCGU/cgu.container";
import { CGVPageContainer } from "./views/legalCGV/cgv.container";
import { SupportPageContainer } from "./views/suppport/support.container";
import { TicketResPageContainer } from "./views/ticketRes/ticketRes.container";
import { CgvEditingPageContainer } from "./views/cgvEditing/cgvEditing.container";
import { ReportsPageContainer } from "./views/reports/reports.container";
import { CoachPageContainer } from "./views/coach/coach.container";
import { UserDetailsPageContainer } from "./views/userDetails/userDetails.container";
import { ReportResolvePageContainer } from "./views/reportResolve/reportResolve.container";
import { StatusRequestPageContainer } from "./views/statusrequest/statusrequest.container";


export const RoutePath = () => (
  
  <Routes>
    <Route path="/" element={<LoginPageContainer />} />
    <Route path="/dashboard" element={ <ProtectedRoute> <DashboardPageContainer /> </ProtectedRoute> } />
    <Route path="/list-of-users" element={<ProtectedRoute> <ListOfUsersPageContainer /> </ProtectedRoute> } />
    <Route path="/configration" element={ <ProtectedRoute> <ConfigrationPageContainer /> </ProtectedRoute> } />
    <Route path="/legal-cgu" element={<ProtectedRoute> <CGUPageContainer /> </ProtectedRoute>} />
    <Route path="/legal-cgv" element={<ProtectedRoute> <CGVPageContainer /> </ProtectedRoute>} />
    <Route path="/support" element={<ProtectedRoute> <SupportPageContainer /> </ProtectedRoute>} />
    <Route path="/ticket-resolve" element={<ProtectedRoute> <TicketResPageContainer /> </ProtectedRoute>} />
    <Route path="/cgv-editing" element={<ProtectedRoute> <CgvEditingPageContainer /> </ProtectedRoute>} />
    <Route path="/reports" element={<ProtectedRoute> <ReportsPageContainer /> </ProtectedRoute>} />
    <Route path="/report-resolve" element={<ProtectedRoute> <ReportResolvePageContainer /> </ProtectedRoute>} />
    <Route path="/coach" element={<ProtectedRoute> <CoachPageContainer /> </ProtectedRoute>} />
    <Route path="/user-details/:id" element={<ProtectedRoute> <UserDetailsPageContainer /> </ProtectedRoute>} />
    <Route path="/status-request" element={<ProtectedRoute> <StatusRequestPageContainer /> </ProtectedRoute>} />

  </Routes>
);
