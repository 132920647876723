import CGUPage from './cgu.component';
import { connect } from 'react-redux';
import { getCgu, editCgu, updateCgu } from './../../api/cgu/cguAction';

const mapDispatchToProps = {
    getCgu,
    editCgu,
    updateCgu
}

const mapStateToProps = state => ({
    isLoading: state.cguPage.isLoading,
    isError: state.cguPage.isError,
    isLoggedIn: state.cguPage.isLoggedIn,
    isSaving: state.cguPage.isSaving,
    cgu: state.cguPage.cgu,
    cguList: state.cguPage.cguList,
});

export const CGUPageContainer = connect(mapStateToProps, mapDispatchToProps)(CGUPage); 