import * as actionTypes from "./cguActionType";

const initialState = {
    isLoading: false,
    isError: false,
    isSaving: false,
    isLoggedIn: localStorage.getItem("admin_access_token") !== null ? true : false,
    loggedUser: localStorage.getItem("admin_details") !== null ? JSON.parse(localStorage.getItem("admin_details")) : {},
    cgu: [],
    cguList: {},
    count: 0
    
}

export const cguReducer = (state = initialState, action) => {
    switch (action.type) {   
        case actionTypes.ALL_CGU:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false,
            };
        case actionTypes.ALL_CGU_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false,
            };
        case actionTypes.ALL_CGU_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: true,
                cgu: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : [],
            };  
        case actionTypes.EDIT_CGU:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false
            };
        case actionTypes.EDIT_CGU_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false
            };
        case actionTypes.EDIT_CGU_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: true,
                cguList: action.payload.data,
            }; 
        case actionTypes.UPDATE_CGU:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false
            };
        case actionTypes.UPDATE_CGU_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false
            };
        case actionTypes.UPDATE_CGU_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: true,
            };    
        default: 
            return state
    }
}