import * as actionTypes from "./cgvActionType";
import axios from "axios";
import { baseUrl } from "../../config";
import { toast } from 'react-toastify';

export const getCgv = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(getCgvInit());
        axios.get(`${baseUrl}/cgv`, data, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(getCgvSuccess(response.data.data));
            } else{
                dispatch(getCgvFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(getCgvFailure());
        });
    };
};

export const editCgv = (id) => {
    return (dispatch) => {
        dispatch(editCgvInit());
        axios.get(`${baseUrl}/cgv/${id}`, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(editCgvSuccess(response.data.data));
            } else{
                dispatch(editCgvFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(editCgvFailure());
        });
    };
};

export const updateCgv = (data) => {
    return (dispatch) => {
        dispatch(updateCgvInit());
        axios.put(`${baseUrl}/cgv/${data.id}`, data)
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(updateCgvSuccess(response.data.data));
                console.warn(response.data.data);
            } else{
                dispatch(updateCgvFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(updateCgvFailure());
        });
    };
};

export const getCgvInit = () => ({
    type: actionTypes.ALL_CGV
  });
  
export const getCgvFailure = data => ({
    type: actionTypes.ALL_CGV_FAILURE
});

export const getCgvSuccess = data => ({
    type: actionTypes.ALL_CGV_SUCCESS,
    payload: { data }
});

export const editCgvInit = () => ({
    type: actionTypes.EDIT_CGV
  });
  
export const editCgvFailure = data => ({
    type: actionTypes.EDIT_CGV_FAILURE
});

export const editCgvSuccess = data => ({
    type: actionTypes.EDIT_CGV_SUCCESS,
    payload: { data }
});

export const updateCgvInit = () => ({
    type: actionTypes.UPDATE_CGV
  });
  
export const updateCgvFailure = data => ({
    type: actionTypes.UPDATE_CGV_FAILURE
});

export const updateCgvSuccess = data => ({
    type: actionTypes.UPDATE_CGV_SUCCESS,
    payload: { data }
});
