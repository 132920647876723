import * as actionTypes from "./userActionType";
import axios from "axios";
import { baseUrl } from "./../../config";
import { toast } from 'react-toastify';

export const userLogin = (data) => {
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem("admin_access_token")}` } };
    return (dispatch) => {
      dispatch(userLoginInit());
      axios.post(`${baseUrl}/admin/authenticate`, data, config)
      .then((response)=>{
        localStorage.setItem("admin_details", JSON.stringify(response.data.data));
        localStorage.setItem("admin_access_token", response.data.data.token);
        toast.success(response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });  
        dispatch(userLoginSuccess(response.data.data));
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(userLoginFailure());
    })
  };
};

export const fetchList = (data) => {
 console.warn('fetchList calling....')
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem('admin_access_token')}` } };
    return (dispatch) => {
      dispatch(fetchListInit());
      axios.get(`${baseUrl}/user`)
      .then((response)=>{
        dispatch(fetchListSuccess(response.data.data));
      })
      .catch(function (error) {
        dispatch(fetchListFailure());
      })
  };
};

export const fetchListStatus = (data) => {
  console.warn('fetchList calling....')
   const config = { headers: { Authorization: `Bearer ${localStorage.getItem('admin_access_token')}` } };
     return (dispatch) => {
       dispatch(StatusRequestInit());
       axios.get(`${baseUrl}/user/official`)
       .then((response)=>{
         dispatch(StatusRequestSuccess(response.data.data));
       })
       .catch(function (error) {
         dispatch(StatusRequestFailure());
       })
   };
 };

 export const StatusRequestInit = () => ({
  type: actionTypes.ALL_STATUS_REQUEST
});

export const StatusRequestFailure = data => ({
  type: actionTypes.ALL_STATUS_REQUEST_FAILURE
});

export const StatusRequestSuccess = data => ({
  type: actionTypes.ALL_STATUS_REQUEST_SUCCESS,
  payload: { data }
});

export const userdetail = (id) => {
  console.log("console")
  const config = { headers: { Authorization: null }};
  console.warn("configgg", config)
    return (dispatch) => {
      dispatch(userdetailInit());
      axios.get(`${baseUrl}/user/get-user/${id}`, config)
      .then((response)=>{       
        localStorage.setItem("user_access_token", response.data.data.token);
        dispatch(userdetailSuccess(response.data.data));
      })
      .catch(function (error) {
          dispatch(userdetailFailure());
      })
  };
};


export const updateUser = (data) => {
  console.warn('token:-',localStorage)
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem('user_access_token')}` } };
  return (dispatch) => {
    dispatch(userUpdateInit());
    axios.put(`${baseUrl}/user/${data.id}`, data)
    .then((response)=>{
        dispatch(userUpdateInitSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(userUpdateInitFailure());
    })
};
};

export const userUpdateInit = () => ({
  type: actionTypes.USER_UPDATE,
});

export const userUpdateInitFailure = (data) => ({
  type: actionTypes.USER_UPDATE_FAILURE,
});

export const userUpdateInitSuccess = (data) => ({
  type: actionTypes.USER_UPDATE_SUCCESS,
  payload: { data },
});


export const deleteUser = (id) => {
  console.warn('deleteUser id :- ',id)
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem('user_access_token')}` } };
  return (dispatch) => {
    dispatch(deleteUserInit());
    axios.delete(`${baseUrl}/user/${id}`,  config)
    .then((response)=>{
      if(response.data.message === "Success"){
        dispatch(deleteUserInitSuccess(response.data.data));
        toast.success("User Deleted Succefully", {
          theme: "colored",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    } else{
        dispatch(deleteUserInitFailure());
    }

    })
    .catch(function (error) {
        dispatch(deleteUserInitFailure());
    })
};
};

export const deleteUserInit = () => ({
  type: actionTypes.USER_DELETE,
});

export const deleteUserInitFailure = (data) => ({
  type: actionTypes.USER_DELETE_FAILURE,
});

export const deleteUserInitSuccess = (data) => ({
  type: actionTypes.USER_DELETE_SUCCESS,
  payload: { data },
});




export const sendMail = (email) => {
  return (dispatch) => {
    dispatch(deleteMailInit());
    axios.get(`${baseUrl}/user/send-email/${email}`)
    .then((response)=>{
        dispatch(deleteMailInitSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(deleteMailInitFailure());
    })
};
};

export const deleteMailInit = () => ({
  type: actionTypes.MAIL_DELETE,
});

export const deleteMailInitFailure = (data) => ({
  type: actionTypes.MAIL_DELETE_FAILURE,
});

export const deleteMailInitSuccess = (data) => ({
  type: actionTypes.MAIL_DELETE_SUCCESS,
  payload: { data },
});


export const userOfficial = (data, parms) => {
  const isOfficial = { is_official: parms };

  return (dispatch) => {
    dispatch(userBecomeOfficialInit());
    axios.put(`${baseUrl}/user/admin-user-official/${data}`, isOfficial)
    .then((response)=>{
        dispatch(userBecomeOfficialSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(userBecomeOfficialFailure());
    })
};
};

export const userLoginInit = () => ({
  type: actionTypes.USER_LOGIN,
});

export const userLoginFailure = (data) => ({
  type: actionTypes.USER_LOGIN_FAILURE,
});

export const userLoginSuccess = (data) => ({
  type: actionTypes.USER_LOGIN_SUCCESS,
  payload: { data },
});

export const fetchListInit = () => ({
  type: actionTypes.FETCH_LIST
});

export const fetchListFailure = data => ({
  type: actionTypes.FETCH_LIST_FAILURE
});

export const fetchListSuccess = data => ({
  type: actionTypes.FETCH_LIST_SUCCESS,
  payload: { data }
});

export const userdetailInit = () => ({
  type: actionTypes.FETCH_USER_DETAIL
});

export const userdetailFailure = data => ({
  type: actionTypes.FETCH_USER_DETAIL_FAILURE
});

export const userdetailSuccess = data => ({
  type: actionTypes.FETCH_USER_DETAIL_SUCCESS,
  payload: { data }
});

export const userBecomeOfficialInit = () => ({
  type: actionTypes.FETCH_OFFICIAL
});

export const userBecomeOfficialFailure = data => ({
  type: actionTypes.FETCH_OFFICIAL_FAILURE
});

export const userBecomeOfficialSuccess = data => ({
  type: actionTypes.FETCH_OFFICIAL_SUCCESS,
  payload: { data }
});


export const fitnessdetail = (id) => {
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem('admin_access_token')}` } };

    return (dispatch) => {
      dispatch(fitnessdetailInit());
      axios.get(`${baseUrl}/fitness/admin/${id}`)
      .then((response)=>{       
       
        dispatch(fitnessdetailSuccess(response.data.data));
      })
      .catch(function (error) {
          dispatch(fitnessdetailFailure());
      })
  };
};

export const fitnessdetailInit = () => ({
  type: actionTypes.FETCH_FITNESS_DETAIL
});

export const fitnessdetailFailure = data => ({
  type: actionTypes.FETCH_FITNESS_DETAIL_FAILURE
});

export const fitnessdetailSuccess = data => ({
  type: actionTypes.FETCH_FITNESS_DETAIL_SUCCESS,
  payload: { data }
});



export const nutritionDetail = (id) => {
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem('admin_access_token')}` } };

    return (dispatch) => {
      dispatch(nutritiondetailInit());
      axios.get(`${baseUrl}/nutration/admin/${id}`)
      .then((response)=>{       
       
        dispatch(nutritiondetailSuccess(response.data.data));
      })
      .catch(function (error) {
          dispatch(nutritiondetailFailure());
      })
  };
};

export const nutritiondetailInit = () => ({
  type: actionTypes.FETCH_NUTRATION_DETAIL
});

export const nutritiondetailFailure = data => ({
  type: actionTypes.FETCH_NUTRATION_DETAIL_FAILURE
});

export const nutritiondetailSuccess = data => ({
  type: actionTypes.FETCH_NUTRATION_DETAIL_SUCCESS,
  payload: { data }
});


export const shareFoodDetail = (id) => {
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem('admin_access_token')}` } };

    return (dispatch) => {
      dispatch(sharefooddetailInit());
      axios.get(`${baseUrl}/share-food/admin/${id}`)
      .then((response)=>{       
       
        dispatch(sharefooddetailSuccess(response.data.data));
      })
      .catch(function (error) {
          dispatch(sharefooddetailFailure());
      })
  };
};

export const sharefooddetailInit = () => ({
  type: actionTypes.FETCH_SHARE_FOOD_DETAIL
});

export const sharefooddetailFailure = data => ({
  type: actionTypes.FETCH_SHARE_FOOD_DETAIL_FAILURE
});

export const sharefooddetailSuccess = data => ({
  type: actionTypes.FETCH_SHARE_FOOD_DETAIL_SUCCESS,
  payload: { data }
});


export const shareworkoutDetail = (id) => {
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem('admin_access_token')}` } };

    return (dispatch) => {
      dispatch(shareshareworkoutdetailInit());
      axios.get(`${baseUrl}/share-workout/admin/${id}`)
      .then((response)=>{       
       
        dispatch(shareshareworkoutdetailSuccess(response.data.data));
      })
      .catch(function (error) {
          dispatch(shareshareworkoutdetailFailure());
      })
  };
};

export const shareshareworkoutdetailInit = () => ({
  type: actionTypes.FETCH_SHARE_WORKOUT_DETAIL
});

export const shareshareworkoutdetailFailure = data => ({
  type: actionTypes.FETCH_SHARE_WORKOUT_DETAIL_FAILURE
});

export const shareshareworkoutdetailSuccess = data => ({
  type: actionTypes.FETCH_SHARE_WORKOUT_DETAIL_SUCCESS,
  payload: { data }
});


export const getActivityAll = () => {
  return (dispatch) => {
      dispatch(getActivityInit());
      axios.get(`${baseUrl}/activity`)
          .then((response) => {

              dispatch(getActivitySuccess(response.data.data));
          })
          .catch(function (error) {
              dispatch(getActivityFailure());
          })
  };
};

export const getActivityInit = () => ({
  type: actionTypes.ALL_ACTIVITY
});

export const getActivityFailure = data => ({
  type: actionTypes.ALL_ACTIVITY_FAILURE
});

export const getActivitySuccess = data => ({
  type: actionTypes.ALL_ACTIVITY_SUCCESS,
  payload: { data }
});


export const getObjective = (data) => {
 
  return (dispatch) => {
    dispatch(getObjectiveInit());
    axios.get(`${baseUrl}/objective`)
        .then((response) => {

            dispatch(getObjectiveSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(getObjectiveFailure());
        })
};
};

export const getObjectiveInit = () => ({
  type: actionTypes.ALL_OBJECTIVE
});

export const getObjectiveFailure = data => ({
  type: actionTypes.ALL_OBJECTIVE_FAILURE
});

export const getObjectiveSuccess = data => ({
  type: actionTypes.ALL_OBJECTIVE_SUCCESS,
  payload: { data }
});