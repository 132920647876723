import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const MyPagination = ({ totalPosts, paginate, currentPage, postPerPage }) => {
    const pageNumbers = [];
    if(currentPage > 1) {
        pageNumbers.push(<Pagination.Prev key="prev" onClick={() => paginate(currentPage - 1)} />)
    }

    for(let page = 1; page <= Math.ceil(totalPosts / postPerPage); page++) {
        pageNumbers.push(
            <Pagination.Item key={page} data-page={page} active={page === currentPage} onClick={() => paginate(page)}>
                {page}
            </Pagination.Item>
        )
    }

    if (currentPage <= 0) {
        pageNumbers.push(<Pagination.Next key="next" onClick={() => paginate(currentPage + 1)} />)
    }

    return (
        <>
            <Pagination size="sm" style={{float: "right"}}>{pageNumbers}</Pagination>
        </>
        
    )
}

export default MyPagination;