import * as actionTypes from "./hashtagActionType";
import axios from "axios";
import { baseUrl } from "./../../config";
import { toast } from 'react-toastify';

export const allHashtag = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(allHashtagInit());
        axios.get(`${baseUrl}/hashtag`, data, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(allHashtagSuccess(response.data.data));
            } else{
                dispatch(allHashtagFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(allHashtagFailure());
        });
    };
};

export const createHashtag = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(createHashtagInit());
        axios.post(`${baseUrl}/hashtag/store`, data, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                toast.success("create Successfully", {}); 
                dispatch(createHashtagSuccess(response.data.data));
            } else{
                dispatch(createHashtagFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(createHashtagFailure());
        });
    };
};
export const editHashtag = (id) => {
    return (dispatch) => {
        dispatch(editHashtagInit());
        axios.get(`${baseUrl}/hashtag/${id}`, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                dispatch(editHashtagSuccess(response.data.data));
            } else{
                dispatch(editHashtagFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(editHashtagFailure());
        });
    };
};

export const updateHashtag = (data) => {
    return (dispatch) => {
        dispatch(updateHashtagInit());
        axios.put(`${baseUrl}/hashtag/${data.id}`, data)
        .then((response)=>{
            if(response.data.message === "Success"){
                toast.success("Update Successfully", {}); 
                dispatch(updateHashtagSuccess(response.data.data));
                console.warn(response.data.data);
            } else{
                dispatch(updateHashtagFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(updateHashtagFailure());
        });
    };
};

export const deleteHashtag = (id) => {
    console.log("id :---", id);
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("")}` } };
    return (dispatch) => {
        dispatch(deleteHashtagInit());
        axios.delete(`${baseUrl}/hashtag/${id}`, {})
        .then((response)=>{
            if(response.data.message === "Success"){
                toast.success("Delete Successfully", {}); 
                dispatch(deleteHashtagSuccess(response.data.data));
            } else{
                dispatch(deleteHashtagFailure());
            }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
            dispatch(deleteHashtagFailure());
        });
    };
};

export const createHashtagInit = () => ({
  type: actionTypes.CREATE_HASHTAG
});

export const createHashtagFailure = data => ({
  type: actionTypes.CREATE_HASHTAG_FAILURE
});

export const createHashtagSuccess = data => ({
  type: actionTypes.CREATE_HASHTAG_SUCCESS,
  payload: { data }
});

export const allHashtagInit = () => ({
    type: actionTypes.ALL_HASHTAG
  });
  
export const allHashtagFailure = data => ({
    type: actionTypes.ALL_HASHTAG_FAILURE
});

export const allHashtagSuccess = data => ({
    type: actionTypes.ALL_HASHTAG_SUCCESS,
    payload: { data }
});

export const editHashtagInit = () => ({
    type: actionTypes.EDIT_HASHTAG
  });
  
export const editHashtagFailure = data => ({
    type: actionTypes.EDIT_HASHTAG_FAILURE
});

export const editHashtagSuccess = data => ({
    type: actionTypes.EDIT_HASHTAG_SUCCESS,
    payload: { data }
});

export const updateHashtagInit = () => ({
    type: actionTypes.UPDATE_HASHTAG
  });
  
export const updateHashtagFailure = data => ({
    type: actionTypes.UPDATE_HASHTAG_FAILURE
});

export const updateHashtagSuccess = data => ({
    type: actionTypes.UPDATE_HASHTAG_SUCCESS,
    payload: { data }
});

export const deleteHashtagInit = () => ({
    type: actionTypes.DELETE_HASHTAG
  });
  
export const deleteHashtagFailure = data => ({
    type: actionTypes.DELETE_HASHTAG_FAILURE
});

export const deleteHashtagSuccess = data => ({
    type: actionTypes.DELETE_HASHTAG_SUCCESS,
    payload: { data }
});