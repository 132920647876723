import * as actionTypes from "./activityActionType";

const initialState = {
    isLoading: false,
    isError: false,
    isSaving: false,
    isLoggedIn: localStorage.getItem("admin_access_token") !== null ? true : false,
    loggedUser: localStorage.getItem("admin_details") !== null ? JSON.parse(localStorage.getItem("admin_details")) : {},
    activity: [],
    activityList: {},
    count: 0
    
}

export const activityReducer = (state = initialState, action) => {
    switch (action.type) {   
        case actionTypes.ALL_ACTIVITY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false,
            };
        case actionTypes.ALL_ACTIVITY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false,
            };
        case actionTypes.ALL_ACTIVITY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: false,
                activity: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : [],
            };
        case actionTypes.CREATE_ACTIVITY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false
            };
        case actionTypes.CREATE_ACTIVITY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false
            };
        case actionTypes.CREATE_ACTIVITY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: true
            };  
        case actionTypes.EDIT_ACTIVITY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false
            };
        case actionTypes.EDIT_ACTIVITY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false
            };
        case actionTypes.EDIT_ACTIVITY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: true,
                activityList: action.payload.data,
            }; 
        case actionTypes.UPDATE_ACTIVITY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false
            };
        case actionTypes.UPDATE_ACTIVITY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false
            };
        case actionTypes.UPDATE_ACTIVITY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: true,
            }; 
        case actionTypes.DELETE_ACTIVITY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false
            };
        case actionTypes.DELETE_ACTIVITY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false
            };
        case actionTypes.DELETE_ACTIVITY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: true
            };    
        default: 
            return state
    }
}