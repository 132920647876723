import TicketResPage from './ticketRes.component';
import { connect } from 'react-redux';
// import { userLogin } from './../../api/user/userAction';

// const mapDispatchToProps = {
//     userLogin
// }

// const mapStateToProps = state => ({
//     isLoading: state.userPage.isLoading,
//     isError: state.userPage.isError,
//     isLoggedIn: state.userPage.isLoggedIn,
// });

export const TicketResPageContainer = connect(null)(TicketResPage); 