export const ALL_CGU = "ALL_CGU";
export const ALL_CGU_SUCCESS = "ALL_CGU_SUCCESS";
export const ALL_CGU_FAILURE = "ALL_CGU_FAILURE";

export const EDIT_CGU = "EDIT_CGU";
export const EDIT_CGU_SUCCESS = "EDIT_CGU_SUCCESS";
export const EDIT_CGU_FAILURE = "EDIT_CGU_FAILURE";

export const UPDATE_CGU = "UPDATE_CGU";
export const UPDATE_CGU_SUCCESS = "UPDATE_CGU_SUCCESS";
export const UPDATE_CGU_FAILURE = "UPDATE_CGU_FAILURE";