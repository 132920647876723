import * as actionTypes from "./cgvActionType";

const initialState = {
    isLoading: false,
    isError: false,
    isSaving: false,
    isLoggedIn: localStorage.getItem("admin_access_token") !== null ? true : false,
    loggedUser: localStorage.getItem("admin_details") !== null ? JSON.parse(localStorage.getItem("admin_details")) : {},
    cgv: [],
    cgvList: {},
    count: 0
    
}

export const cgvReducer = (state = initialState, action) => {
    switch (action.type) {   
        case actionTypes.ALL_CGV:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false,
            };
        case actionTypes.ALL_CGV_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false,
            };
        case actionTypes.ALL_CGV_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: true,
                cgv: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : [],
            };  
        case actionTypes.EDIT_CGV:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false
            };
        case actionTypes.EDIT_CGV_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false
            };
        case actionTypes.EDIT_CGV_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: true,
                cgvList: action.payload.data,
            }; 
        case actionTypes.UPDATE_CGV:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaving: false
            };
        case actionTypes.UPDATE_CGV_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaving: false
            };
        case actionTypes.UPDATE_CGV_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaving: true,
            };    
        default: 
            return state
    }
}